<div
    class="expr-segment !rounded-full min-h-[32px] !py-1 !px-3 !bg-sky-700 text-white text-base"
    [ngClass]="{
        selected: isSelected,
        error: showError,
        number: isNumber
    }"
    [matTooltip]="error | expressionError"
    matTooltipClass="error-tooltip"
    (click)="onClick($event)"
>
    {{ expression.toString() }}
</div>
