import { Component, Input } from '@angular/core';
import { ExpressionEditContext } from 'src/app/shared/models/expression/edit-context';
import { ExpressionMixin } from '../../expression.mixin';
import { ExpressionPrimitive } from 'src/app/shared/models/expression/primitive';
import { ExpressionType } from 'src/app/shared/models/expression/type';

@Component({
    selector: 'expr-primitive',
    templateUrl: './expression-primitive.component.html',
    styleUrls: ['../expression.scss'],
})
export class ExpressionPrimitiveComponent extends ExpressionMixin {
    @Input() expression: ExpressionPrimitive;
    @Input() editContext: ExpressionEditContext;

    get isNumber() {
        return this.expression.type == ExpressionType.Number;
    }
}
