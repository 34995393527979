<div class="h-full p-3">
    <div class="btn-bar gap-1">
        <button mat-flat-button color="primary" (click)="addSignals()">
            <mat-icon svgIcon="plus" class="btn-icon"></mat-icon>
            <span>{{ 'chart_setup.add_signals' | translate }}</span>
        </button>
        <button mat-stroked-button (click)="addCalculatedSignal()">
            <mat-icon
                svgIcon="chart-timeline-variant"
                class="btn-icon"
            ></mat-icon>
            <span>{{ 'chart_setup.add_calculated_signal' | translate }}</span>
        </button>
    </div>
    <div class="list-container">
        <div
            cdkDropList
            class="mx-2 mb-1 h-[calc(100%-16px)] rounded-2xl"
            (cdkDropListDropped)="drop($event)"
        >
            <div *ngFor="let signal of chart.calculatedSignals">
                <div
                    class="mb-1 flex h-full select-none items-start justify-center overflow-clip rounded-lg"
                    cdkDrag
                    [cdkDragDisabled]="hasSelectedSignal"
                    cdkDragPreviewContainer="parent"
                    cdkDragBoundary=".list-container"
                    cdkDragLockAxis="y"
                >
                    <div
                        class="mb-1 flex h-16 overflow-clip rounded-lg border border-transparent"
                        *cdkDragPlaceholder
                    ></div>
                    <div
                        class="flex w-full flex-row items-stretch justify-start overflow-clip rounded-lg border border-gray-200 bg-white dark:border-gray-600 dark:bg-gray-700"
                    >
                        <div
                            class="w-16 border-r border-r-gray-200 bg-gray-200 dark:border-r-gray-600 dark:bg-gray-600"
                            [ngClass]="{ 'cursor-move': !hasSelectedSignal }"
                            cdkDragHandle
                        >
                            <div
                                class="flex h-full items-center justify-center"
                            >
                                <mat-icon
                                    class="text-gray-700 dark:text-gray-200"
                                    svgIcon="{{
                                        hasSelectedSignal
                                            ? selectedSignalUuid == signal.uuid
                                                ? 'application-variable-outline'
                                                : 'chart-timeline-variant'
                                            : 'drag-horizontal'
                                    }}"
                                >
                                </mat-icon>
                            </div>
                        </div>
                        <div class="flex w-full flex-col">
                            <div
                                class="signal-bar flex h-16 w-full flex-row items-center justify-between"
                                (click)="editSignal(signal)"
                            >
                                <div class="flex flex-col p-3">
                                    <div class="text-base leading-none">
                                        {{ signal.name }}
                                    </div>
                                    <div
                                        class="pt-1 text-xs leading-none text-gray-400"
                                    >
                                        {{ getDeviceNamesString(signal) }}
                                    </div>
                                </div>
                                <div
                                    class="mr-2 flex flex-row items-center justify-end"
                                >
                                    <button mat-icon-button>
                                        <mat-icon
                                            svgIcon="application-variable-outline"
                                        ></mat-icon>
                                    </button>
                                    <button
                                        mat-icon-button
                                        color="warn"
                                        (click)="removeSignal(signal)"
                                    >
                                        <mat-icon
                                            svgIcon="delete-outline"
                                        ></mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div
                                *ngIf="selectedSignalUuid == signal.uuid"
                                class="flex h-[50vh] flex-col border-t border-t-gray-200 dark:border-t-gray-600"
                            >
                                <div
                                    class="signal-settings flex w-full flex-row items-center justify-start gap-4 p-3"
                                >
                                    <mat-form-field
                                        appearance="fill"
                                        subscriptSizing="dynamic"
                                    >
                                        <mat-label>
                                            {{
                                                'can_database.signal.name'
                                                    | translate
                                            }}
                                        </mat-label>
                                        <input
                                            matInput
                                            [formControl]="signalName"
                                        />
                                    </mat-form-field>
                                    <!-- A spacer between settings -->
                                    <div class="flex-1"></div>
                                    <button
                                        mat-stroked-button
                                        (click)="copySignal(signal)"
                                    >
                                        <mat-icon
                                            svgIcon="content-copy"
                                            class="btn-icon"
                                        ></mat-icon>
                                        <span>
                                            {{
                                                'chart_setup.copy_signal'
                                                    | translate
                                            }}
                                        </span>
                                    </button>
                                    <button
                                        mat-stroked-button
                                        (click)="selectDevices(signal)"
                                    >
                                        <mat-icon
                                            svgIcon="access-point"
                                            class="btn-icon"
                                        ></mat-icon>
                                        <span>
                                            {{
                                                'btn.select_devices' | translate
                                            }}
                                        </span>
                                    </button>
                                </div>
                                <expr-workspace
                                    class="grow"
                                    [editContext]="editContext"
                                >
                                </expr-workspace>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
