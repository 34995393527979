import { NgModule } from '@angular/core';
import { AppMaterialModule } from '../../services/app-material/app-material.module';
import { ChartMapComponent } from './chart-map.component';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
    imports: [
        AppMaterialModule,
        GoogleMapsModule
    ],
    exports: [ChartMapComponent],
    declarations: [ChartMapComponent]
})
export class ChartMapModule {}
