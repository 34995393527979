import { Expression } from './expression';
import { ExpressionList } from './list';
import { ExpressionNull } from './null';
import { ExpressionOperation } from './operation';
import { ExpressionPrimitive } from './primitive';
import { ExpressionType, isType } from './type';

export class ExpressionEditContext {
    private _root: Expression;
    private _rootType: ExpressionType;
    private _onUpdate: (expr: Expression) => void;
    private _selected: Expression;
    private _rootHistory: Expression[];
    private _showSyntaxErrors: boolean;
    private _options: Expression[];

    get root(): Expression {
        return this._root;
    }

    get rootType(): ExpressionType {
        return this._rootType;
    }

    get selected(): Expression {
        return this._selected;
    }

    /**
     * The type expected at the current selected position.
     */
    get expectedType(): ExpressionType {
        if (this._selected == null) {
            return ExpressionType.Null;
        }
        if (this.rootIsSelected) {
            return this._rootType;
        }
        function findParent(
            expression: Expression,
            childId: string
        ): Expression {
            for (const child of expression.children) {
                if (child.uuid == childId) {
                    return expression;
                }
                const result = findParent(child, childId);
                if (result != null) {
                    return result;
                }
            }
            return null;
        }
        const parent = findParent(this._root, this.selectedUuid);
        if (parent instanceof ExpressionOperation) {
            return parent.operator.inputTypes[
                parent.children.indexOf(this.selected)
            ];
        } else {
            return ExpressionType.Any;
        }
    }

    get showSyntaxErrors(): boolean {
        return this._showSyntaxErrors;
    }

    get selectedUuid(): string {
        if (!this._selected) {
            return '';
        }
        return this._selected.uuid;
    }

    get rootIsCorrectType(): boolean {
        return isType(this.root.type, this._rootType);
    }

    get rootIsNull(): boolean {
        return this.root instanceof ExpressionNull;
    }

    get rootIsSelected(): boolean {
        return this._root.uuid == this.selectedUuid;
    }

    get rootIsValid(): boolean {
        return this.rootIsCorrectType && this.root.isSyntaxValid;
    }

    get selectedIsPrimitive(): boolean {
        return this._selected instanceof ExpressionPrimitive;
    }

    get selectedIsList(): boolean {
        return this._selected instanceof ExpressionList;
    }

    get selectedIsNull(): boolean {
        return this._selected instanceof ExpressionNull;
    }

    get canDeleteSelected(): boolean {
        return this.selectedUuid && !this.selectedIsNull;
    }

    get canUndo(): boolean {
        return this._rootHistory.length > 0;
    }

    get options(): Expression[] {
        return this._options;
    }

    set options(o) {
        this._options = o;
    }

    get filteredOptions(): Expression[] {
        return this._options.filter((c) => isType(c.type, this.expectedType));
    }

    constructor(
        root: Expression,
        rootType: ExpressionType,
        onUpdate?: (expr: Expression) => void
    ) {
        this._root = root;
        this._rootType = rootType;
        this._onUpdate = onUpdate;
        this._selected = null;
        this._rootHistory = [];
        this._showSyntaxErrors = false;
        this._options = [];
    }

    toggleSyntaxCheck() {
        this._showSyntaxErrors = !this._showSyntaxErrors;
        this._selected = null;
    }

    deleteSelected() {
        this._rootHistory.push(this._root.clone());
        this._root = this._root.replace(
            this.selectedUuid,
            new ExpressionNull()
        ).expression;
        this.handleUpdate();
        this._selected = null;
    }

    insert(item: Expression) {
        if (!this._selected) {
            return;
        }
        this._rootHistory.push(this._root.clone());
        this._root = this._root.replace(this.selectedUuid, item).expression;
        this.handleUpdate();
        this._selected = item;
    }

    select(item: Expression) {
        this._showSyntaxErrors = false;
        this._selected = item;
    }

    undo() {
        this._root = this._rootHistory.pop();
        this.handleUpdate();
        this._selected = null;
    }

    /**
     * The expression was updated.
     * Call the onUpdate function, if it exists.
     */
    handleUpdate() {
        if (this._onUpdate) {
            this._onUpdate(this._root);
        }
    }
}
