import { Component, Input } from '@angular/core';
import { ExpressionEditContext } from 'src/app/shared/models/expression/edit-context';
import { ExpressionList } from 'src/app/shared/models/expression/list';
import { ExpressionMixin } from '../../expression.mixin';

@Component({
    selector: 'expr-list',
    templateUrl: './expression-list.component.html',
    styleUrls: ['../expression.scss'],
})
export class ExpressionListComponent extends ExpressionMixin {
    @Input() expression: ExpressionList;
    @Input() editContext: ExpressionEditContext;
}
