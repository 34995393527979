import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { CommonModule } from 'src/app/common/common.module';
import { AppMaterialModule } from 'src/app/services/app-material/app-material.module';
import { ChartMapModule } from '../chart-map/chart-map.module';
import { ChartSetupModule } from '../chart-setup/chart-setup.module';
import { DataTableModule } from '../data-table/data-table.module';
import { LineChartModule } from '../line-chart/line-chart.module';
import { ChartCardComponent } from './chart-card.component';

@NgModule({
    imports: [
        AppMaterialModule,
        ScrollingModule,
        CommonModule,
        ChartMapModule,
        LineChartModule,
        DataTableModule,
        ChartSetupModule,
    ],
    exports: [ChartCardComponent],
    declarations: [ChartCardComponent],
})
export class ChartCardModule {}
