import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SimpleInputDialog } from 'src/app/dialogs/simple-input-dialog/simple-input-dialog.component';
import { Expression } from 'src/app/shared/models/expression/expression';
import { ExpressionEditContext } from 'src/app/shared/models/expression/edit-context';
import { ExpressionList } from 'src/app/shared/models/expression/list';
import { ExpressionPrimitive } from 'src/app/shared/models/expression/primitive';
import {
    ExpressionType,
    typeName,
} from 'src/app/shared/models/expression/type';
import { DialogService } from 'src/app/services/dialog/dialog.service';

enum DisplayContent {
    Options,
    HelpMessage,
    SyntaxResults,
}
@Component({
    selector: 'expr-workspace',
    templateUrl: './expression-workspace.component.html',
})
export class ExpressionWorkspaceComponent implements OnInit {
    @Input() editContext: ExpressionEditContext;

    DisplayContent = DisplayContent;
    _rootTypeNameTranslated: string;

    get content(): DisplayContent {
        if (this.editContext.showSyntaxErrors) {
            return DisplayContent.SyntaxResults;
        }
        if (this.editContext.selected == null) {
            return DisplayContent.HelpMessage;
        }
        return DisplayContent.Options;
    }

    get rootTypeName(): string {
        return this._rootTypeNameTranslated;
    }

    constructor(
        private translate: TranslateService,
        private dialog: DialogService
    ) {}

    ngOnInit(): void {
        this.translate
            .get(typeName(this.editContext.rootType))
            .subscribe((t) => {
                this._rootTypeNameTranslated = t;
            });
    }

    deselect() {
        this.editContext.select(null);
    }

    selectNewItem(item: Expression) {
        this.editContext.insert(item.clone());
    }

    async editPrimitive() {
        if (!this.editContext.selectedIsPrimitive) {
            return;
        }
        const primitive: ExpressionPrimitive = this.editContext
            .selected as ExpressionPrimitive;
        const result = await this.dialog.open(SimpleInputDialog, {
            data: {
                title: 'expressions.edit_value',
                text: 'expressions.edit_value_description',
                label: 'expressions.value',
                isNumber: primitive.type == ExpressionType.Number,
                input: primitive.raw,
            },
        });
        if (result) {
            primitive.raw = result;
            this.editContext.handleUpdate();
        }
    }

    shrinkList() {
        if (this.editContext.selectedIsList) {
            (this.editContext.selected as ExpressionList).shrink();
            this.editContext.handleUpdate();
        }
    }

    growList() {
        if (this.editContext.selectedIsList) {
            (this.editContext.selected as ExpressionList).grow();
            this.editContext.handleUpdate();
        }
    }
}
