<div class="h-full overflow-x-auto">
    <as-split class="h-full" direction="vertical" gutterSize="4">
        <as-split-area size="50" minSize="30">
            <app-chart-card
                *ngIf="mainChartIndex !== -1"
                class="h-full"
                #chartCard
                [charts]="charts"
                [(index)]="mainChartIndex"
                [messages]="messages"
                [deviceNames]="deviceNames"
                [(isEditMode)]="isEditMode"
                [showToolbar]="showToolbar"
                (deleteEvent)="deleteChart.emit(mainChartIndex)"
                (saveChanges)="saveChart.emit()"
            >
            </app-chart-card>
        </as-split-area>
        <as-split-area
            *ngIf="isSplitView"
            id="secondary"
            size="50"
            minSize="30"
        >
            <app-chart-card
                *ngIf="secondaryChartIndex !== -1"
                #chartCard
                [charts]="charts"
                [(index)]="secondaryChartIndex"
                [messages]="messages"
                [deviceNames]="deviceNames"
                [(isEditMode)]="isEditMode"
                [showToolbar]="showToolbar"
                (deleteEvent)="deleteChart.emit(secondaryChartIndex)"
                (saveChanges)="saveChart.emit()"
            >
            </app-chart-card>
        </as-split-area>
    </as-split>
</div>
