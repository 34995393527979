import { ExpressionEditContext } from '../shared/models/expression/edit-context';
import {
    Expression,
    IExpressionError
} from '../shared/models/expression/expression';

type Constructor<T = {}> = new (...args: any[]) => T;

function expressionMixin<T extends Constructor<{}>>(Base: T = class {} as any) {
    return class extends Base {
        expression: Expression;
        editContext: ExpressionEditContext;

        get isSelected() {
            return (
                !!this.editContext &&
                this.expression.uuid == this.editContext.selectedUuid
            );
        }

        get showError(): boolean {
            return !!this.error.term;
        }

        get error(): IExpressionError {
            if (!!this.editContext && this.editContext.showSyntaxErrors) {
                return this.expression.error;
            }
            return {};
        }

        onClick(event) {
            if (this.editContext) {
                this.editContext.select(this.expression);
            }
            event.stopPropagation();
        }
    };
}

export const ExpressionMixin = expressionMixin();
