<h1 mat-dialog-title>{{ 'chart_setup.select_add_signals' | translate }}</h1>
<div class="mat-typography" mat-dialog-content>
    <div class="w-full">
        <div class="mat-subtitle-2">
            {{ 'chart_setup.select_message' | translate }}
        </div>
        <sz-select [(ngModel)]="selectedMessageId" [options]="messageOptions" />
    </div>
    <div class="btn-bar mt-4">
        <button
            mat-flat-button
            *ngIf="signalOptions?.length > 0"
            color="primary"
            (click)="selectAllSignals()"
        >
            <mat-icon svgIcon="check-all" class="btn-icon"></mat-icon>
            <span>{{ 'chart_setup.select_all_signals' | translate }}</span>
        </button>
        <p *ngIf="signalOptions.length === 0">
            {{ 'chart_setup.no_message_signals' | translate }}
        </p>
    </div>
    <div class="mt-2 h-[40vh] overflow-auto">
        <mat-selection-list *ngIf="selectedMessage" class="full-width">
            <mat-list-option
                class="mr-2 flex"
                *ngFor="let signal of signalOptions"
                [checkboxPosition]="before"
                (click)="toggleSignal(signal)"
                [selected]="isSignalSelected(signal)"
                [value]="signal"
                >{{ signal.name }}
            </mat-list-option>
        </mat-selection-list>
    </div>
    <sz-dialog-button-bar
        (primaryClick)="handleSave()"
        (secondaryClick)="dialogRef.close()"
    />
</div>
