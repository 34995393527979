<div
    class="expr-segment !bg-emerald-700"
    [ngClass]="{ selected: isSelected, error: showError }"
    [matTooltip]="error | expressionError"
    matTooltipClass="error-tooltip"
    (click)="onClick($event)"
>
    <ng-container *ngIf="expression.children.length != 2; else twoOperand">
        <ng-container *ngFor="let child of expression.children; index as i">
            <div class="h-6 text-xl text-white first:pl-2">
                {{ words[i] ?? '' }}
            </div>
            <expr-child [expression]="child" [editContext]="editContext">
            </expr-child>
        </ng-container>
    </ng-container>
    <ng-template #twoOperand>
        <expr-child
            [expression]="expression.children[0]"
            [editContext]="editContext"
        ></expr-child>
        <div class="h-6 text-xl leading-tight text-white first:pl-2">
            <div *ngIf="hasIcon; else textOperator">
                <mat-icon [svgIcon]="expression.operator.icon"></mat-icon>
            </div>
            <ng-template #textOperator>{{
                expression.operator.operator
            }}</ng-template>
        </div>
        <expr-child
            [expression]="expression.children[1]"
            [editContext]="editContext"
        >
        </expr-child>
    </ng-template>
</div>
