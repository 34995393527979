<p
    class="warning-text"
    [innerHTML]="
        'time_difference.warning_text'
            | translate: { default_time_diff: defaultTimeDifference }
    "
></p>
<google-map
    *ngIf="mapsLoader.isLoaded"
    width="100%"
    height="100%"
    [center]="center"
    [options]="options"
    [zoom]="14"
>
    <ng-container *ngFor="let markers of markerChunks">
        <ng-container *ngFor="let arr of markers; let i = index">
            <ng-container *ngIf="arr && arr.length">
                <map-marker
                    *ngFor="let m of arr; let i = index"
                    #markerRef="mapMarker"
                    [position]="{ lat: m.lat, lng: m.lng }"
                    [options]="{
                        icon:
                            i === 0
                                ? startIcon
                                : i === arr.length - 1
                                  ? endIcon
                                  : imgSrc
                    }"
                    (mapClick)="windowRef.open(markerRef); getDateTime(m.time)"
                >
                    <map-info-window #windowRef="mapInfoWindow">
                        <div>
                            <p *ngIf="i === 0">
                                {{ 'gps.first_point' | translate }}
                            </p>
                            <p *ngIf="i === arr.length - 1">
                                {{ 'gps.last_point' | translate }}
                            </p>
                            {{ m.label }}
                        </div>
                    </map-info-window>
                </map-marker>
            </ng-container>
            <map-polyline
                [path]="arr"
                [options]="{ strokeColor: 'red', strokeWeight: 2 }"
            >
            </map-polyline>
            <map-marker
                #markerRef="mapMarker"
                *ngIf="isChartsSync"
                [position]="{ lat: marker.lat, lng: marker.lng }"
            >
                <map-info-window #windowRef="mapInfoWindow">
                    <div>
                        {{ marker.label }}
                    </div>
                </map-info-window>
            </map-marker>
        </ng-container>
    </ng-container>
</google-map>
