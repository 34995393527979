<h1 mat-dialog-title>{{ 'frequency_distribution.heading' | translate }}</h1>
<div mat-dialog-content class="mat-typography">
    <div>
        <form fxLayout="column" fxLayoutGap="3px" [formGroup]="form" class="mt-2 h-[40vh] overflow-auto">
            <mat-form-field class="full-width">
                <mat-label>{{ 'frequency_distribution.select_variable' | translate }}</mat-label>
                <mat-select formControlName="selectedVariable" (selectionChange)="findMinMax($event)">
                    <mat-option *ngFor="let item of data.minMaxArray" [value]="item.selectedVariable">
                        {{ item.selectedVariable }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>{{ 'frequency_distribution.min_value' | translate }}</mat-label>
                <input matInput formControlName="minValue" class="form-control" />
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>{{ 'frequency_distribution.max_value' | translate }}</mat-label>
                <input matInput formControlName="maxValue" class="form-control" />
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>{{ 'frequency_distribution.range_steps' | translate }}</mat-label>
                <input matInput formControlName="frequency" class="form-control" type="number" />
                <mat-error *ngIf="frequency.touched && frequency.invalid && frequency?.errors?.min">
                    {{ 'frequency_distribution.frequency_value_error' | translate }}
                    {{ minimumValue }}.
                </mat-error>
            </mat-form-field>
        </form>
    </div>
    <sz-dialog-button-bar (primaryClick)="handleSave()" (secondaryClick)="dialogRef.close()" />
</div>
