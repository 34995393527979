import { NgModule } from '@angular/core';
import { AppMaterialModule } from 'src/app/services/app-material/app-material.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { ChartSetupComponent } from './chart-setup.component';
import { ChartGeneralSetupModule } from '../chart-general-setup/chart-general-setup.module';
import { ChartSignalsModule } from '../chart-signals/chart-signals.module';
import { ChartFilterModule } from '../chart-filter/chart-filter.module';
import { ExpressionModule } from 'src/app/expression/expression.module';

@NgModule({
    imports: [
        AppMaterialModule,
        ScrollingModule,
        TableVirtualScrollModule,
        ChartGeneralSetupModule,
        ChartSignalsModule,
        ChartFilterModule,
        ExpressionModule
    ],
    declarations: [ChartSetupComponent],
    exports: [ChartSetupComponent]
})
export class ChartSetupModule { }
