<mat-card class="mat-elevation-z0 h-full overflow-clip !rounded-none p-0">
    <div class="flex h-full flex-col">
        <div
            *ngIf="showToolbar"
            class="toolbar flex flex-row items-center justify-start bg-gray-100 dark:bg-gray-750"
            (wheel)="onWheel($event)"
        >
            <ng-container *ngIf="charts.length > 1">
                <button
                    mat-button
                    (click)="previousChart()"
                    class="h-9 !min-w-[40px] !rounded-none !border-none !p-0"
                >
                    <mat-icon svgIcon="chevron-left" class="!mr-0" />
                </button>
                <div class="w-14 text-center text-sm text-gray-400">
                    {{ paginationString }}
                </div>
                <button
                    mat-button
                    (click)="nextChart()"
                    class="h-9 !min-w-[40px] !rounded-none !border-none !p-0"
                >
                    <mat-icon svgIcon="chevron-right" class="!mr-0" />
                </button>
            </ng-container>
            <div class="w-full flex-1 truncate py-1.5 pl-2 pr-0 text-base">
                {{ chart.title }}
            </div>
            <ng-container *ngIf="!isLoading">
                <ng-container [ngSwitch]="isEditMode">
                    <ng-template [ngSwitchCase]="false">
                        <button
                            class="h-9 !min-w-[40px] !rounded-none !border-none !p-0"
                            mat-button
                            [matTooltip]="
                                'btn.frequency_distribution' | translate
                            "
                            (click)="checkPercentageDistribution()"
                            *ngIf="chart.type == chartType.LINE"
                        >
                            <mat-icon
                                svgIcon="equalizer"
                                class="!mr-0"
                            ></mat-icon>
                        </button>
                        <button
                            class="h-9 !min-w-[40px] !rounded-none !border-none !p-0"
                            mat-button
                            [matTooltip]="
                                (isEmptyRegionHidden
                                    ? 'reports.show_empty_regions'
                                    : 'reports.hide_empty_regions'
                                ) | translate
                            "
                            (click)="hideEmptyRegions()"
                            *ngIf="chart.type === chartType.LINE"
                        >
                            <mat-icon class="!mr-0">
                                {{
                                    isEmptyRegionHidden
                                        ? 'visibility_off'
                                        : 'visibility'
                                }}
                            </mat-icon>
                        </button>
                        <button
                            class="h-9 !min-w-[40px] !rounded-none !border-none !p-0"
                            mat-button
                            matTooltip="{{ 'btn.export' | translate }}"
                            [disabled]="
                                !chart.labels || chart.chartPoints.length === 0
                            "
                            (click)="exportToCsv(chart)"
                        >
                            <mat-icon svgIcon="download" class="!mr-0" />
                        </button>
                        <button
                            class="h-9 !min-w-[40px] !rounded-none !border-none !p-0"
                            mat-button
                            matTooltip="{{
                                'reports.toggle_y_axis_scale' | translate
                            }}"
                            [disabled]="chart.type !== chartType.LINE"
                            (click)="toggleScale()"
                        >
                            <mat-icon
                                svgIcon="arrow-expand-vertical"
                                class="!mr-0"
                            />
                        </button>
                        <button
                            class="h-9 !min-w-[40px] !rounded-none !border-none !p-0"
                            mat-button
                            matTooltip="{{
                                'can_database.signals' | translate
                            }}"
                            (click)="goToSignals()"
                        >
                            <mat-icon
                                svgIcon="chart-timeline-variant"
                                class="!mr-0"
                            />
                        </button>
                        <button
                            class="h-9 !min-w-[40px] !rounded-none !border-none !p-0"
                            mat-button
                            matTooltip="{{ 'common.filter' | translate }}"
                            (click)="goToFilter()"
                        >
                            <mat-icon svgIcon="filter-cog" class="!mr-0" />
                        </button>
                        <button
                            class="h-9 !min-w-[40px] !rounded-none !border-none !p-0"
                            mat-button
                            matTooltip="{{ 'btn.setup' | translate }}"
                            (click)="goToSetup()"
                        >
                            <mat-icon svgIcon="tune-vertical" class="!mr-0" />
                        </button>
                    </ng-template>
                    <ng-template [ngSwitchCase]="true">
                        <button
                            class="h-9 !min-w-[40px] !rounded-none !border-none !p-0"
                            mat-button
                            matTooltip="{{ 'btn.save' | translate }}"
                            (click)="handleSave()"
                        >
                            <mat-icon svgIcon="check" class="!mr-0" />
                        </button>
                    </ng-template>
                </ng-container>
            </ng-container>
        </div>
        <div
            class="card-image-area"
            [ngClass]="showToolbar ? 'h-[calc(100%-36px)]' : 'h-full'"
        >
            <div
                *ngIf="isLoading; else cardContent"
                class="flex h-full items-center justify-center"
            >
                <div *ngIf="!chart.tooManyValues; else tooManyValues">
                    <sz-illustration name="loading" title="loading">
                        <div class="w-full px-16">
                            <mat-progress-bar
                                *ngIf="
                                    chart.progressValue === 0;
                                    else determinateProgress
                                "
                                mode="indeterminate"
                            />

                            <ng-template #determinateProgress>
                                <mat-progress-bar
                                    mode="determinate"
                                    [value]="chart.progressValue"
                                />
                            </ng-template>
                        </div>
                    </sz-illustration>
                </div>
                <ng-template #tooManyValues>
                    <sz-illustration
                        name="visual-data"
                        title="chart.too_much_data"
                        description="chart.too_much_data.description"
                    />
                </ng-template>
            </div>
            <ng-template #cardContent>
                <ng-container [ngSwitch]="isEditMode">
                    <ng-template [ngSwitchCase]="false">
                        <ng-container
                            *ngIf="
                                chart.calculatedSignals.length > 0;
                                else noSignals
                            "
                        >
                            <ng-container [ngSwitch]="chart?.type">
                                <ng-template [ngSwitchCase]="chartType.MAP">
                                    <app-chart-map [data]="chart.chartPoints" />
                                </ng-template>
                                <ng-template [ngSwitchCase]="chartType.TABLE">
                                    <app-data-table
                                        [data]="chart.chartPoints"
                                        [labels]="chart.labels"
                                    />
                                </ng-template>
                                <ng-template [ngSwitchCase]="chartType.LINE">
                                    <div class="data-stats">
                                        <ul>
                                            <li
                                                *ngFor="let stat of minMaxArray"
                                            >
                                                <span class="variable-name">{{
                                                    stat.selectedVariable
                                                }}</span>
                                                <p>
                                                    {{
                                                        'reports.min_value'
                                                            | translate
                                                    }}:
                                                    <span>{{ stat.min }}</span>
                                                </p>
                                                <p>
                                                    {{
                                                        'reports.max_value'
                                                            | translate
                                                    }}:
                                                    <span>{{ stat.max }}</span>
                                                </p>
                                                <p>
                                                    {{
                                                        'reports.mean_value'
                                                            | translate
                                                    }}:
                                                    <span>{{ stat.mean }}</span>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <app-line-chart
                                        #eLineChart
                                        [index]="index"
                                        [data]="chart.chartPoints"
                                        [labels]="chart.labels"
                                        [thresholds]="chart.chartThresholds"
                                        (minMaxData)="setMinMax($event)"
                                        [timeDifferential]="
                                            isEmptyRegionHidden
                                                ? timeDifferenceDetails
                                                : 0
                                        "
                                    />
                                </ng-template>
                            </ng-container>
                        </ng-container>
                        <ng-template #noSignals>
                            <div
                                class="no-signals flex h-full items-center justify-center"
                            >
                                <sz-illustration
                                    name="no-data"
                                    title="chart.no_signals"
                                    description="chart.no_signals.description"
                                >
                                    <button
                                        mat-flat-button
                                        color="primary"
                                        (click)="goToSignals()"
                                    >
                                        {{ 'can_database.signals' | translate }}
                                    </button>
                                </sz-illustration>
                            </div>
                        </ng-template>
                    </ng-template>
                    <ng-template [ngSwitchCase]="true">
                        <app-chart-setup
                            [chart]="chart"
                            [(setupTab)]="setupTab"
                            [messages]="messages"
                            [deviceNames]="deviceNames"
                            (deleteChart)="deleteChart()"
                        />
                    </ng-template>
                </ng-container>

                <div
                    class="distribution-wrap"
                    *ngIf="
                        chart.type == chartType.LINE &&
                        percentageFrequency &&
                        percentageFrequency.length
                    "
                >
                    <h4>
                        <strong
                            >{{
                                'reports.frequency_distribution_text'
                                    | translate
                            }}{{ selectedVariableName }}</strong
                        >
                    </h4>
                    <div class="table-wrap">
                        <table CELLSPACING="0" CELLPADDING="0">
                            <thead>
                                <tr>
                                    <th>
                                        {{
                                            'frequency_distribution.range_heading'
                                                | translate
                                        }}
                                    </th>
                                    <th>
                                        {{
                                            'frequency_distribution.frequency_heading'
                                                | translate
                                        }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="
                                        let frequency of percentageFrequency
                                    "
                                >
                                    <td>{{ frequency.range }}</td>
                                    <td>{{ frequency.value }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</mat-card>
