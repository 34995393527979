import { NgModule } from '@angular/core';
import { AppMaterialModule } from 'src/app/services/app-material/app-material.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { ChartSignalsComponent } from './chart-signals.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ExpressionModule } from 'src/app/expression/expression.module';

@NgModule({
    imports: [
        AppMaterialModule,
        ScrollingModule,
        TableVirtualScrollModule,
        DragDropModule,
        ExpressionModule
    ],
    declarations: [ChartSignalsComponent],
    exports: [ChartSignalsComponent]
})
export class ChartSignalsModule { }
