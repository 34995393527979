import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ChartSyncService } from 'src/app/services/chart-sync/chart-sync.service';
import { SettingsService } from 'src/app/services/user/settings.service';
import * as echarts from 'echarts/core';
import { GridComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { DateTimeService } from 'src/app/services/date-time.service';
import { ChartThreshold } from '../chart.model';
import { DEFAULT_PRECISION_VALUE } from 'src/app/services/constants';
import { EchartData, MinMaxData } from '../chart.model';
import { roundNumber } from 'src/app/services/constants';
import { ChartType, DispatchActionType } from '../chart.model';
import { TimeDifferenceDetail } from '../chart.model';

@Component({
    selector: 'app-line-chart',
    templateUrl: './line-chart.component.html',
})
export class LineChartComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public data: any;
    @Input() public labels: any;
    @Input() public index: any;
    @Input() public thresholds: ChartThreshold[];
    @Output() public minMaxData: EventEmitter<MinMaxData[]> =
        new EventEmitter();
    @Input() public timeDifferential: TimeDifferenceDetail;

    @ViewChild('eLineChart', { static: true }) eLineChart: ElementRef;

    options: any;
    myChart;
    scale = true;
    resizeObserver: ResizeObserver;
    isDarkMode = false;
    isHighlightChecked = true;
    previousIndex: number;
    maxValueLength = 6;

    constructor(
        private chartSyncService: ChartSyncService,
        private settings: SettingsService,
        private dateTimeService: DateTimeService,
    ) {}

    ngOnInit() {
        echarts.use([
            GridComponent,
            LineChart,
            CanvasRenderer,
            UniversalTransition,
        ]);
        this.resizeObserver = new ResizeObserver(() => {
            if (this.myChart) {
                this.myChart.resize();
            }
        });

        this.settings.values$.subscribe(() => {
            this.isDarkMode = this.settings.get('extras.dark-mode');
            if (this.data && this.labels) {
                this.options = this.setEchartParameters(
                    this.data,
                    this.labels,
                    this.thresholds,
                );
                this.eChartInit();
            }
        });

        this.resizeObserver.observe(this.eLineChart.nativeElement);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes) {
            return;
        }
        if (
            (this.data && this.labels && this.thresholds) ||
            this.timeDifferential.timeDifference > 0
        ) {
            this.options = this.setEchartParameters(
                this.data,
                this.labels,
                this.thresholds,
            );
            this.eChartInit();
            this.myChart.on('datazoom', () => {
                const option = this.myChart.getOption();
                const startValue: number = new Date(
                    option.dataZoom[0].startValue,
                ).getTime();
                const endValue: number = new Date(
                    option.dataZoom[0].endValue,
                ).getTime();

                const dataInWindow = this.data.filter(
                    (i) =>
                        new Date(i[0]).getTime() >= startValue &&
                        new Date(i[0]).getTime() <= endValue,
                );
                const filteredChartData: EchartData[] =
                    this.generateChartValues(
                        dataInWindow,
                        this.labels,
                        this.thresholds,
                    );
                this.minMaxData.emit(this.calculateMinMax(filteredChartData));
            });
        }
        if (!this.myChart) {
            this.eChartInit();
        }
    }

    ngOnDestroy(): void {
        if (this.myChart) {
            this.myChart.dispose();
        }
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    getImage() {
        return this.myChart.getDataURL({
            excludeComponents: ['dataZoom', 'legend'],
        });
    }

    toggleScale() {
        this.scale = !this.scale;
        this.myChart.setOption({
            yAxis: {
                scale: this.scale,
            },
            dataZoom: [
                {
                    type: 'slider',
                    xAxisIndex: [0],
                    filterMode: 'filter',
                },
                {
                    type: 'slider',
                    yAxisIndex: [0],
                    show: !this.scale,
                    disabled: !this.scale,
                    filterMode: 'none',
                },
            ],
            animation: 100,
            animationDurationUpdate: 100,
        });
        this.myChart.dispatchAction({
            type: 'dataZoom',
            dataZoomIndex: 1,
            start: 0,
            end: 100,
        });
    }

    setEchartParameters(data, labels, thresholds: ChartThreshold[]) {
        const valueSeries = this.generateChartValues(data, labels, thresholds);
        this.chartSyncService.echartPoints = valueSeries[0]?.data;
        const valueLegends: any[] = labels.map((label) => {
            return {
                name: label,
                icon: 'rect',
            };
        });

        const chartOptions: any = {
            hoverLayerThreshold: Infinity,
            style: {
                lineWidth: 0.5,
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params) => this.customTooltip(params),
            },
            dataZoom: [
                {
                    type: 'slider',
                    xAxisIndex: [0],
                    filterMode: 'filter',
                },
            ],
            xAxis: {
                type: 'time',
            },
            yAxis: {
                type: 'value',
                scale: true,
            },
            legend: {
                data: valueLegends,
                textStyle: { color: this.isDarkMode ? 'white' : 'black' },
            },
            series: valueSeries,
        };
        this.minMaxData.emit(this.calculateMinMax(valueSeries));
        return chartOptions;
    }

    /**
     * Creates a new tooltip with the timestamp according to the selected format.
     * @param params
     * @returns
     */
    private customTooltip(params): string {
        return `<div style="margin-bottom:10px;line-height:1;">
        ${this.dateTimeService.dateTimeString(params[0].axisValueLabel)}
        </div>${this.tooltipVariables(params)}`;
    }

    /**
     * Creates inner labels for the tooltip
     * @param params
     * @returns
     */
    private tooltipVariables(params): string {
        let variables = '';
        for (let i = 0; i < params.length; i++) {
            variables += `<div style="margin-bottom:10px;line-height:1;"><span>
            ${params[i].marker}</span><span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">
            ${params[i].seriesName}</span><span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">
            ${params[i].value[1]}</span><div style="clear:both"></div></div>`;
        }
        return variables;
    }

    /**
     * Create Echart value series based on different symbols selected
     * @param data
     * @param labels
     * @param thresholds
     * @returns
     */
    private generateChartValues(
        data,
        labels: string[],
        thresholds: ChartThreshold[],
    ) {
        return data
            .reduce(
                (current, item) => {
                    const timestamp = item[0];
                    item.forEach((value, i) => {
                        if (i > 0 && value != null) {
                            current[i - 1].push([timestamp, value]);
                        }
                    });
                    return current;
                },
                Array.from(labels).map(() => []),
            )
            .map((points, index) => ({
                name: labels[index],
                type: 'line',
                showSymbol: false,
                smooth: false,
                lineStyle: { normal: { width: 1 } },
                markLine: {
                    data: thresholds,
                    precision: DEFAULT_PRECISION_VALUE,
                },
                data: [].concat(...points.map(this.createChartPoints())),
            }));
    }

    eChartInit() {
        this.myChart = echarts.init(this.eLineChart.nativeElement);
        this.myChart.clear();
        this.myChart.group = 'echart';

        setTimeout(() => {
            this.myChart.resize();
        });

        if (this.options != undefined) {
            this.myChart.setOption(this.options);
        }
    }

    onHighlight(chartSync: boolean) {
        if (chartSync) {
            this.myChart.on('highlight', (evt) => {
                const option = this.myChart.getOption();
                if (this.isHighlightChecked && option) {
                    const timeDateValue: Date = new Date(
                        option.xAxis[0]?.axisPointer?.value ?? 0,
                    );
                    this.chartSyncService.highlightChartPoint(
                        timeDateValue,
                        ChartType.MAP,
                    );
                }
            });
        } else {
            this.myChart.off(DispatchActionType.Highlight);
        }
    }

    /**
     * Will finds the minimum and maximum value from the data displayed in chart window.
     * @param echartData
     * @returns
     */
    calculateMinMax(echartData: EchartData[]): MinMaxData[] {
        if (!echartData) {
            return;
        }

        const minMaxArray = echartData.map((element) => {
            const min = this.minValue(element.data);
            const max = this.maxValue(element.data);
            const mean = this.calculateMean(element.data);
            const selectedVariable = element.name;
            return { min, max, mean, selectedVariable };
        });

        return minMaxArray;
    }

    /**
     * Will find the minimum value from the chart data
     * @param arr
     * @returns
     */
    minValue(arr: [Date, number][]): number | string {
        if (!arr || !arr.length) {
            return '-';
        }

        let min = Number.MAX_VALUE;
        arr.forEach((e) => min > e[1] && (min = e[1]));
        return min;
    }

    /**
     * Will find the maximum value from the chart data
     * @param arr
     * @returns
     */
    maxValue(arr: [Date, number][]): number | string {
        if (!arr || !arr.length) {
            return '-';
        }

        let max = -Number.MAX_VALUE;
        arr.forEach((e) => max < e[1] && (max = e[1]));
        return max;
    }

    /**
     * Will find the mean value for the data shown in chart window
     * @param arr
     * @returns
     */
    calculateMean(arr: [Date, number][]): number | string {
        if (!arr || !arr.length) {
            return '-';
        }

        const mean = arr.reduce((sum, el) => sum + el[1], 0) / arr.length;
        return isNaN(mean) ? '-' : roundNumber(mean);
    }

    /**
     * Highlights a point on the chart.
     * @param {number} index - Index of the point to be highlighted.
     */
    highlightPoint(index: number) {
        this.isHighlightChecked = false;
        if (this.previousIndex !== undefined) {
            this.chartDispatchAction(
                DispatchActionType.Downplay,
                this.previousIndex,
            );
        }
        this.chartDispatchAction(DispatchActionType.Highlight, index);
        this.chartDispatchAction(DispatchActionType.ShowTip, index);
        this.previousIndex = index;
        this.isHighlightChecked = true;
    }

    /**
     * Dispatches an action to a chart at the given index.
     * @param {string} type - The type of action to dispatch.
     * @param {number} index - The index of the data point to apply the action to.
     * @returns {void}
     */
    chartDispatchAction(type: string, index: number) {
        this.myChart.dispatchAction({
            type: type,
            seriesIndex: 0,
            dataIndex: index,
        });
    }

    /**
     * Creates chart points in the form of [Date, number] from a given array.
     * @returns {(v: [number, number | string], index: number, array: [number, number][]) =>
     * [Date, number | string][]} Returns a function which creates chart points in the form of [Date, number] from a given array.
     */
    private createChartPoints(): (
        v: [Date, number | string],
        index: number,
        array: [number, number][],
    ) => [Date, number | string][] {
        return (v, index, array): [Date, number | string][] => {
            const date: Date = v[0];
            const nextValue: [number, number | string] = array[index + 1];
            const value: number | string =
                typeof v[1] === 'number'
                    ? v[1]
                    : v[1].toString().length > this.maxValueLength
                      ? parseFloat(v[1]).toExponential(this.maxValueLength)
                      : parseFloat(v[1]);

            if (!nextValue) return [[date, value]];
            const millisecondsValue: number = new Date(nextValue[0]).getTime();
            const timeDifferenceInMillis = millisecondsValue - date.getTime();
            const getSecondsValue: number = Math.floor(
                timeDifferenceInMillis / 1000,
            );
            return getSecondsValue >= this.timeDifferential.timeDifference &&
                this.timeDifferential.isEmptyRegionHidden
                ? [
                      [date, value],
                      [null, null],
                  ]
                : [[date, value]];
        };
    }
}
