import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MINIMUM_VALUE } from 'src/app/services/constants';

@Component({
    selector: 'app-time-difference-dialog',
    templateUrl: './time-difference-dialog.component.html',
    styleUrl: './time-difference-dialog.component.scss',
})
export class TimeDifferenceComponent {
    form: FormGroup;

    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<TimeDifferenceComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { timeDifferenceValue: number },
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            time: [
                this.data.timeDifferenceValue,
                [Validators.required, Validators.min(MINIMUM_VALUE)],
            ],
        });
    }

    /**
     * Method is called when time difference value changes and dialog is closed.
     */
    setTimeDifferenceValue(): void {
        if (this.form.valid) {
            this.dialogRef.close(this.form.value);
        }
    }
}
