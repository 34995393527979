import { Component, Input } from '@angular/core';
import { ExpressionEditContext } from 'src/app/shared/models/expression/edit-context';
import { ExpressionNull } from 'src/app/shared/models/expression/null';
import { ExpressionMixin } from '../../expression.mixin';

@Component({
    selector: 'expr-null',
    templateUrl: './expression-null.component.html',
    styleUrls: ['../expression.scss'],
})
export class ExpressionNullComponent extends ExpressionMixin {
    @Input() expression: ExpressionNull;
    @Input() editContext: ExpressionEditContext;
}
