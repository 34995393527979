import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { AngularSplitModule } from 'angular-split';
import { AppMaterialModule } from 'src/app/services/app-material/app-material.module';
import { ChartCardModule } from '../chart-card/chart-card.module';
import { ChartSetupModule } from '../chart-setup/chart-setup.module';
import { DataTableModule } from '../data-table/data-table.module';
import { LineChartModule } from '../line-chart/line-chart.module';
import { ChartMapModule } from '../chart-map/chart-map.module';
import { ChartSplitViewComponent } from './chart-split-view.component';

@NgModule({
  imports: [
    AppMaterialModule,
    ScrollingModule,
    AngularSplitModule,
    ChartMapModule,
    LineChartModule,
    DataTableModule,
    ChartSetupModule,
    ChartCardModule
  ],
  exports: [ ChartSplitViewComponent ],
  declarations: [ ChartSplitViewComponent ],
})
export class ChartSplitViewModule { }
