import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { Chart, ChartMessage } from '../chart.model';
import { ChartCardComponent } from '../chart-card/chart-card.component';

@Component({
    selector: 'app-chart-split-view',
    templateUrl: './chart-split-view.component.html',
    styleUrls: ['./chart-split-view.component.scss'],
})
export class ChartSplitViewComponent implements OnChanges {
    @Input() isLoading: boolean;
    @Input() charts: Chart[];
    @Input() isSplitView: boolean;
    @Input() messages: ChartMessage[] = [];
    @Input() deviceNames: Map<number, string> = new Map();
    @Input() showToolbar = true;

    @Output() isEditModeChange = new EventEmitter<boolean>();
    @Output() deleteChart = new EventEmitter<number>();
    @Output() saveChart = new EventEmitter();
    @Output() chartQueryList = new EventEmitter<
        QueryList<ChartCardComponent>
    >();
    @ViewChildren('chartCard') chartCards: QueryList<ChartCardComponent>;

    _isEditMode = false;
    mainChartIndex = -1;
    secondaryChartIndex = -1;

    @Input() set isEditMode(val: boolean) {
        this._isEditMode = val;
        this.isEditModeChange.emit(val);
    }

    get isEditMode(): boolean {
        return this._isEditMode;
    }

    // Handle updating the chart index signals when the charts change.
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.charts) {
            const previousLength = changes.charts.previousValue
                ? changes.charts.previousValue.length
                : 0;
            const wasEmpty =
                !changes.charts.previousValue || previousLength == 0;
            const charts = changes.charts.currentValue ?? [];
            if (wasEmpty && !this.isLoading) {
                if (charts.length > 0) {
                    this.mainChartIndex = 0;
                    this.secondaryChartIndex = 0;
                }
                if (charts.length > 1) {
                    this.secondaryChartIndex = 1;
                }
            } else if (charts.length > previousLength) {
                // A chart was added.
                this.mainChartIndex = charts.length - 1;
            } else if (charts.length < previousLength) {
                // A chart was deleted.
                if (this.mainChartIndex >= this.charts.length) {
                    this.mainChartIndex = this.charts.length - 1;
                }
                if (this.secondaryChartIndex >= this.charts.length) {
                    this.secondaryChartIndex = this.charts.length - 1;
                }
            }
        }
        const lineChartCreated = this.chartCards?.some((c) => !!c.lineChart);
        if (lineChartCreated) {
            this.chartQueryList.emit(this.chartCards);
        }
    }
}
