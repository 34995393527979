import { ChartSignal } from 'src/app/chart/chart.model';
import { EvaluationContext } from './evaluation-context';
import { Expression } from './expression';
import { ExpressionKind } from './kind';
import { ExpressionType } from './type';

export class ExpressionVariable extends Expression {
    constructor(public signal: ChartSignal) {
        super();
    }

    get kind(): ExpressionKind {
        return ExpressionKind.Variable;
    }

    get type(): ExpressionType {
        switch (this.signal.dataType) {
            case 'unsigned':
            case 'signed':
            case 'float':
            case 'double':
                return ExpressionType.Number;
            case 'bit':
                return ExpressionType.Boolean;
            case 'char':
            case 'string':
                return ExpressionType.String;
            case 'raw':
            default:
                return ExpressionType.Scalar;
        }
    }

    override get signalIds(): number[] {
        return [this.signal.id];
    }

    get data(): unknown[] {
        return [this.kind, this.signal.id];
    }

    clone(): ExpressionVariable {
        return new ExpressionVariable(this.signal);
    }

    override toString(): string {
        return this.signal.name;
    }

    evaluate(context: EvaluationContext) {
        return context.getSignalValue(this.signal.id);
    }
}
