import { NgModule } from '@angular/core';
import { AppMaterialModule } from 'src/app/services/app-material/app-material.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { ExpressionComponent } from './components/expression/expression.component';
import { ExpressionPrimitiveComponent } from './components/expression-primitive/expression-primitive.component';
import { ExpressionVariableComponent } from './components/expression-variable/expression-variable.component';
import { ExpressionListComponent } from './components/expression-list/expression-list.component';
import { ExpressionOperationComponent } from './components/expression-operation/expression-operation.component';
import { ExpressionNullComponent } from './components/expression-null/expression-null.component';
import { ExpressionWorkspaceComponent } from './components/expression-workspace/expression-workspace.component';
import { ExpressionErrorPipe } from './expression-error.pipe';

@NgModule({
    imports: [AppMaterialModule, ScrollingModule, TableVirtualScrollModule],
    declarations: [
        ExpressionComponent,
        ExpressionListComponent,
        ExpressionNullComponent,
        ExpressionOperationComponent,
        ExpressionPrimitiveComponent,
        ExpressionVariableComponent,
        ExpressionWorkspaceComponent,
        ExpressionErrorPipe
    ],
    exports: [
        ExpressionComponent,
        ExpressionListComponent,
        ExpressionNullComponent,
        ExpressionOperationComponent,
        ExpressionPrimitiveComponent,
        ExpressionVariableComponent,
        ExpressionWorkspaceComponent,
        ExpressionErrorPipe
    ]
})
export class ExpressionModule {}
