import { Component, Input } from '@angular/core';
import { Expression } from 'src/app/shared/models/expression/expression';
import { ExpressionEditContext } from 'src/app/shared/models/expression/edit-context';
import { ExpressionList } from 'src/app/shared/models/expression/list';
import { ExpressionNull } from 'src/app/shared/models/expression/null';
import { ExpressionOperation } from 'src/app/shared/models/expression/operation';
import { ExpressionPrimitive } from 'src/app/shared/models/expression/primitive';
import { ExpressionVariable } from 'src/app/shared/models/expression/variable';

@Component({
    selector: 'expr-child',
    templateUrl: './expression.component.html',
})
export class ExpressionComponent {
    @Input() expression: Expression;
    @Input() editContext: ExpressionEditContext;

    get isSelected() {
        return (
            !!this.editContext &&
            this.expression.uuid == this.editContext.selectedUuid
        );
    }

    get isPrimitive() {
        return this.expression instanceof ExpressionPrimitive;
    }

    get isVariable() {
        return this.expression instanceof ExpressionVariable;
    }

    get isList() {
        return this.expression instanceof ExpressionList;
    }

    get isOperation() {
        return this.expression instanceof ExpressionOperation;
    }

    get isNull() {
        return this.expression instanceof ExpressionNull;
    }
}
