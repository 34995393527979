import { BehaviorSubject, Subject } from 'rxjs';
import { Chart, ChartType, ChartMessage } from './chart.model';

type Constructor<T = {}> = new (...args: any[]) => T;

export function chartPageMixin<T extends Constructor<{}>>(
    Base: T = class {} as any
) {
    return class extends Base {
        protected id: number;
        messages: ChartMessage[] = [];
        isLoading = false;
        isSplitView = false;
        _charts$ = new BehaviorSubject([]);
        _isEditMode$ = new BehaviorSubject(false);

        get isEditMode(): boolean {
            return this._isEditMode$.value;
        }

        set isEditMode(value) {
            this._isEditMode$.next(value);
        }

        get isEditMode$(): Subject<boolean> {
            return this._isEditMode$;
        }

        get charts(): Chart[] {
            return this._charts$.value;
        }

        get charts$(): Subject<Chart[]> {
            return this._charts$;
        }

        constructor(...args: any[]) {
            super(...args);
            this.isEditMode$.subscribe(this.setToolBarButtons.bind(this));
            this.charts$.subscribe(this.updateToolBarButtons.bind(this));
        }

        /**
         * Add a blank chart to the page.
         */
        addNewChart() {
            const chart = new Chart(
                '#' + (this.charts.length + 1),
                ChartType.TABLE,
                [],
                [],
                this.readValues.bind(this)
            );
            chart.id = this.id;
            chart.load();
            this.addChart(chart);
        }

        /**
         * Add a chart to the page.
         */
        addChart(chart: Chart) {
            this._charts$.next([...this.charts, chart]);
        }

        /**
         * Set the list of charts.
         */
        setCharts(charts: Chart[]) {
            this._charts$.next(charts);
        }

        /**
         * Delete a chart from the page.
         */
        deleteChart(index) {
            // We have to do it this way to trigger change detection.
            const copy = Array.from(this.charts);
            copy.splice(index, 1);
            this._charts$.next(copy);
            if (this.charts.length < 2 && this.isSplitView) {
                this.toggleSplitView();
            }
        }

        toggleSplitView() {
            this.isSplitView = !this.isSplitView;
        }

        toggleEditMode() {
            this._isEditMode$.next(!this._isEditMode$.value);
        }

        // This method should be implemented by child classes.
        setToolBarButtons(chart, callback) {
            throw Error('readValues is not implemented');
        }

        // This method should be implemented by child classes.
        updateToolBarButtons(chart, callback) {
            throw Error('readValues is not implemented');
        }

        // This method should be implemented by child classes.
        async readValues(chart) {
            throw Error('readValues is not implemented');
        }
    };
}

export const ChartPageMixin = chartPageMixin();
