import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Chart, ChartType } from '../chart.model';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-chart-general-setup',
    templateUrl: './chart-general-setup.component.html',
})
export class ChartGeneralSetupComponent {
    @Input() set chart(value: Chart) {
        this._chart = value;
        this.title.setValue(value.title);
        this.title.valueChanges.subscribe((title) => {
            if (title.trim().length > 0) {
                this._chart.title = title;
            }
        });
        this.initThresholdForm();
        this.chartTypeSelect.setValue(value.type);
        this.chartTypeSelect.valueChanges.subscribe((type) => {
            this._chart.type = type;
        });
        this.thresholdForm.valueChanges
            .pipe(debounceTime(500))
            .subscribe((type) => {
                if (!this.thresholdForm.invalid) {
                    this._chart.chartThresholds = this.thresholdFormArray.value;
                }
            });

        if (this._chart.chartThresholds) {
            for (const threshold of this._chart.chartThresholds) {
                this.addThreshold(threshold.yAxis.toString());
            }
        }
    }

    @Output() deleteChart = new EventEmitter();

    public chartTypes = [
        {
            name: 'chart_types.line',
            id: ChartType.LINE,
        },
        {
            name: 'chart_types.table',
            id: ChartType.TABLE,
        },
        {
            name: 'chart_types.map',
            id: ChartType.MAP,
        },
    ];

    _chart: Chart;
    title = new FormControl('', Validators.required);
    chartTypeSelect = new FormControl(ChartType.LINE);
    thresholdForm: FormGroup;
    thresholdCount = 6;

    /**
     * Threshold array will be initialized when the component is created
     */
    private initThresholdForm(): void {
        const threshold = new FormArray([]) as any;
        this.thresholdForm = new FormGroup({
            threshold: threshold,
        });
    }

    /**
     * Will return the generated threshold form array
     */
    get thresholdFormArray() {
        return this.thresholdForm.controls['threshold'] as FormArray;
    }

    /**
     * Will delete the selected threshold field from the threshold array
     * @param value have index of the threshold element
     */
    removeThreshold(value: number): void {
        this.thresholdFormArray.removeAt(value);
    }

    /**
     * will add a new threshold element to the threshold array
     * @param value will have the value of the threshold element
     */
    addThreshold(value = '') {
        this.thresholdFormArray.push(
            new FormGroup({
                yAxis: new FormControl(value, Validators.required),
            }),
        );
    }
}
