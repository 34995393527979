import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    NgZone,
    OnInit,
} from '@angular/core';
import { darkModeStyles, lightModeStyles } from 'src/app/home/map-styles';
import { ChartSyncService } from 'src/app/services/chart-sync/chart-sync.service';
import { DEFAULT_TIME_DIFFERENCE } from 'src/app/services/constants';
import { MapsLoaderService } from 'src/app/services/maps-loader/maps-loader.service';
import { SettingsService } from 'src/app/services/user/settings.service';
import { clone, minutesToSeconds } from 'src/app/shared/common';
import { DateTimeService } from 'src/app/services/date-time.service';
import { ChartType, MapPoint } from '../chart.model';
import { Marker } from '../chart.model';

@Component({
    selector: 'app-chart-map',
    templateUrl: './chart-map.component.html',
    styleUrls: ['./chart-map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartMapComponent implements OnInit {
    @Input() gpsFrequency: number;
    markerChunks: Marker[][][];
    timeDifference: number = minutesToSeconds(DEFAULT_TIME_DIFFERENCE);
    defaultTimeDifference: number = DEFAULT_TIME_DIFFERENCE;

    @Input() set data(data) {
        if (data && data.length) {
            this.markers = [];
            this.markerChunks = [];

            this.zone.runOutsideAngular(() => {
                data.forEach((d) => {
                    let result;
                    const len = Math.floor(d.length / 2);
                    for (let i = 1; i <= len; i++) {
                        if (d[i * 2 - 1]) {
                            // check is not null
                            let lat = +d[i * 2 - 1];
                            let lng = +d[i * 2];

                            if (!this.coordinatesAreValid(lat, lng)) {
                                return;
                            }

                            lat = lat > this.MAX_LAT ? this.MAX_LAT : lat;
                            lat = lat < this.MIN_LAT ? this.MIN_LAT : lat;

                            lng = lng > this.MAX_LNG ? this.MAX_LNG : lng;
                            lng = lng < this.MIN_LNG ? this.MIN_LNG : lng;

                            result = {
                                lat, // [Date, lat, lon, lat, lon, ...] - i suggest that 1 - is lat, 2 -lon, 3-lat, ...
                                lng,
                                label: d[0]
                                    ? `${this.dateTimeService.dateTimeString(
                                          d[0],
                                      )}`
                                    : '',
                                time: d[0],
                                draggable: false,
                                isOpenInfo: false,
                            };

                            if (!this.markers[i - 1]) {
                                this.markers[i - 1] = [];
                            }

                            this.markers[i - 1].push(result);
                        }
                    }
                });

                setTimeout(() => {
                    this.createChunks(this.markers);
                    this.cdr.detectChanges();
                });

                this.chartSyncService.markers = this.markers;

                setInterval(() => {
                    this.cdr.detectChanges();
                }, 500);
            });
        }
    }

    MAX_LAT = 85;
    MIN_LAT = -85;
    MAX_LNG = 180;
    MIN_LNG = -180;

    imgSrc = '/assets/img/circle.svg';
    startIcon = {
        url: '/assets/img/pin.svg',
        scaledSize: {
            height: 30,
            width: 30,
        },
    };
    endIcon = {
        url: '/assets/img/finish-pin.svg',
        scaledSize: {
            height: 30,
            width: 30,
        },
    };
    markers: any[] = [];
    interval;
    count = 0;
    offset = '';
    options = { styles: [] };

    constructor(
        private cdr: ChangeDetectorRef,
        private chartSyncService: ChartSyncService,
        private settings: SettingsService,
        private zone: NgZone,
        public mapsLoader: MapsLoaderService,
        private dateTimeService: DateTimeService,
    ) {}

    async ngOnInit() {
        await this.mapsLoader.load(['maps']);
        this.watchDarkMode();
    }

    watchDarkMode() {
        this.settings.values$.subscribe(() => {
            const isDarkMode = this.settings.get('extras.dark-mode');
            const newOptions = clone(this.options);
            newOptions.styles = isDarkMode ? darkModeStyles : lightModeStyles;
            this.options = newOptions;
        });
    }

    public get isChartsSync(): boolean {
        return this.chartSyncService.isChartsSync;
    }

    public get center(): any {
        return this.markers.length == 0
            ? { lat: 0, lng: 0 }
            : this.markers[0][0];
    }

    /**
     * Get the marker value when charts are synced
     */
    public get marker(): MapPoint {
        return this.chartSyncService.marker;
    }

    /**
     * Set marker value when charts are synced
     */
    public set marker(value: MapPoint) {
        this.chartSyncService.marker = value;
    }

    /**
     * Check if the value is inside the range.
     * @param {Number} value
     * @param {Number} min
     * @param {Number} max
     * @returns
     */
    isInBounds(value, min, max) {
        return value >= min && value <= max;
    }

    /**
     * Check that the coordinates are valid.
     * @param {Number} lat
     * @param {Number} lon
     * @returns
     */
    coordinatesAreValid(lat, lon) {
        return (
            (this.isInBounds(lat, this.MIN_LAT, -1e-10) ||
                this.isInBounds(lat, 1e-10, this.MAX_LAT)) &&
            (this.isInBounds(lon, this.MIN_LNG, -1e-10) ||
                this.isInBounds(lon, 1e-10, this.MAX_LNG))
        );
    }

    /**
     * Highlights a chart point on the map.
     * @param {Date} date - The date used to highlight a chart point.
     */
    getDateTime(date: Date): void {
        this.chartSyncService.highlightChartPoint(date, ChartType.LINE);
    }

    /**
     * Creates chunks from markers
     * @param {Marker[][]} markers - Array of markers
     */
    createChunks(markers: Marker[][]): void {
        let chunkArr: Marker[] = [];
        const markersArray: Marker[] = markers[0];
        this.markerChunks = [];

        markersArray.forEach((d, index) => {
            if (!markersArray[index + 1]) {
                return;
            }
            const currentElementDate: Date = new Date(d.time);
            const nextElementDate: Date = new Date(
                markersArray[index + 1].time,
            );
            const timeDifferenceInMillis: number =
                nextElementDate.getTime() - currentElementDate.getTime();
            const getSecondsValue: number = Math.floor(
                timeDifferenceInMillis / 1000,
            );

            if (getSecondsValue >= this.timeDifference) {
                chunkArr.push(d);
                this.markerChunks.push([chunkArr]);
                chunkArr = [];
            } else {
                chunkArr.push(d);
            }
        });

        if (chunkArr.length > 0) {
            this.markerChunks.push([chunkArr]);
        }
    }
}
