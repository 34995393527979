import { Component, Input } from '@angular/core';
import { ExpressionEditContext } from 'src/app/shared/models/expression/edit-context';
import { ExpressionMixin } from '../../expression.mixin';
import { ExpressionVariable } from 'src/app/shared/models/expression/variable';

@Component({
    selector: 'expr-variable',
    templateUrl: './expression-variable.component.html',
    styleUrls: ['../expression.scss'],
})
export class ExpressionVariableComponent extends ExpressionMixin {
    @Input() expression: ExpressionVariable;
    @Input() editContext: ExpressionEditContext;
}
