import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Chart, ChartMessage } from '../chart.model';

@Component({
    selector: 'app-chart-setup',
    templateUrl: './chart-setup.component.html',
})
export class ChartSetupComponent {
    @Input() chart: Chart;
    @Input() setupTab: ChartSetupTab;
    @Input() messages: ChartMessage[];
    @Input() deviceNames: Map<number, string>;
    @Output() setupTabChange = new EventEmitter<ChartSetupTab>();
    @Output() deleteChart = new EventEmitter();

    get selectedTabIndex() {
        return this.setupTab as number;
    }

    set selectedTabIndex(value: number) {
        this.setupTab = value as ChartSetupTab;
        this.setupTabChange.emit(this.setupTab);
    }
}

export enum ChartSetupTab {
    Signals,
    Filter,
    Setup,
}
