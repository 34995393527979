<div class="flex h-full flex-col justify-end self-stretch">
    <div class="flex flex-row items-center justify-center gap-2 p-2">
        <ng-container [ngSwitch]="filterStatus">
            <ng-container *ngSwitchCase="filterStatusEnum.NoFilter">
                <mat-icon
                    svgIcon="minus-circle"
                    class="text-gray-600 dark:text-gray-300"
                ></mat-icon>
                <div>{{ 'chart_setup.filter.no_filter' | translate }}</div>
            </ng-container>
            <ng-container *ngSwitchCase="filterStatusEnum.InvalidFilter">
                <mat-icon svgIcon="close" class="text-red-600"></mat-icon>
                <div>{{ 'chart_setup.filter.invalid_filter' | translate }}</div>
            </ng-container>
            <ng-container *ngSwitchCase="filterStatusEnum.ValidFilter">
                <mat-icon svgIcon="check" class="text-green-600"></mat-icon>
                <div>{{ 'chart_setup.filter.valid_filter' | translate }}</div>
            </ng-container>
        </ng-container>
    </div>
    <expr-workspace class="w-full flex-1" [editContext]="editContext">
    </expr-workspace>
</div>
