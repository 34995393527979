<div class="flex flex-col items-center justify-start p-2">
    <mat-form-field appearance="fill">
        <mat-label>{{ 'title' | translate }}</mat-label>
        <input matInput [formControl]="title" />
    </mat-form-field>
    <div class="flex flex-col justify-center gap-1 pb-4">
        <mat-label class="text-center">
            {{ 'chart_setup.select_chart_type' | translate }}
        </mat-label>
        <mat-button-toggle-group [formControl]="chartTypeSelect">
            <mat-button-toggle
                *ngFor="let type of chartTypes"
                [value]="type.id"
            >
                {{ type.name | translate }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div *ngIf="chartTypeSelect.value == 0">
        <div class="flex flex-row justify-between mt-4">
            <h3>
                {{ 'chart_setup.chart_threshold' | translate }}
            </h3>
            <button
                (click)="addThreshold()"
                class="h-9 !min-w-[40px] !p-0"
                [matTooltip]="'chart_setup.add_threshold' | translate"
                [disabled]="
                    thresholdFormArray.controls.length == thresholdCount
                "
            >
                <mat-icon>add_circle_outline</mat-icon>
            </button>
        </div>
        <form [formGroup]="thresholdForm">
            <div formArrayName="threshold">
                <div
                    *ngFor="
                        let threshold of thresholdFormArray.controls;
                        let i = index
                    "
                    [formGroupName]="i"
                >
                    <mat-form-field>
                        <input
                            matInput
                            type="number"
                            [placeholder]="
                                'chart_setup.chart_threshold_placeholder'
                                    | translate
                            "
                            formControlName="yAxis"
                        />
                    </mat-form-field>
                    <button
                        mat-icon-button
                        type="button"
                        [matTooltip]="
                            'chart_setup.delete_threshold' | translate
                        "
                        (click)="removeThreshold(i)"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </form>
    </div>
    <button mat-flat-button color="warn" (click)="deleteChart.emit()">
        <mat-icon svgIcon="delete" class="btn-icon"></mat-icon>
        <span>{{ 'delete' | translate }}</span>
    </button>
</div>
