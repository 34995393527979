import { Component, Input } from '@angular/core';
import { ExpressionEditContext } from 'src/app/shared/models/expression/edit-context';
import { ExpressionMixin } from '../../expression.mixin';
import { ExpressionOperation } from 'src/app/shared/models/expression/operation';

@Component({
    selector: 'expr-operation',
    templateUrl: './expression-operation.component.html',
    styleUrls: ['../expression.scss'],
})
export class ExpressionOperationComponent extends ExpressionMixin {
    @Input() expression: ExpressionOperation;
    @Input() editContext: ExpressionEditContext;

    get hasIcon(): boolean {
        return !!this.expression.operator.icon;
    }

    get words(): Array<string> {
        return this.expression.operator.words;
    }
}
