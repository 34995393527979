import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MinMaxData } from 'src/app/chart/chart.model';

@Component({
    templateUrl: './frequency-distribution-dialog.component.html',
    styleUrl: './frequency-distribution-dialog.component.scss',
})
export class FrequencyDistributionDialog implements OnInit {
    minimumValue = 0.01;
    form: FormGroup;
    isValueExist = false;

    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<FrequencyDistributionDialog>,
        @Inject(MAT_DIALOG_DATA) public data: { minMaxArray: MinMaxData[] },
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            selectedVariable: [null, Validators.required],
            minValue: [{ value: '', disabled: true }],
            maxValue: [{ value: '', disabled: true }],
            frequency: [
                null,
                [Validators.required, Validators.min(this.minimumValue)],
            ],
        });
    }

    /**
     * get frequency formControl from the formGroup
     */
    get frequency() {
        return this.form.get('frequency');
    }

    /**
     * Performs a calculation and closes the dialog if the form is valid.
     */
    handleSave(): void {
        if (this.form.valid) {
            this.form.controls['minValue'].enable();
            this.form.controls['maxValue'].enable();
            this.dialogRef.close(this.form.value);
        }
    }

    /**
     * Finds the minimum and maximum values based on the selected variable and sets them in the form.
     * @param value - The MatSelectChange event object containing the selected value.
     */
    findMinMax(value: MatSelectChange): void {
        this.data.minMaxArray.forEach((element) => {
            if (element.selectedVariable == value.value) {
                if (element.min != undefined && element.max != undefined) {
                    this.form.controls['minValue'].setValue(element.min);
                    this.form.controls['maxValue'].setValue(element.max);
                    this.isValueExist = true;
                } else {
                    this.form.controls['minValue'].setValue('');
                    this.form.controls['maxValue'].setValue('');
                    this.isValueExist = false;
                }
            }
        });
    }
}
