import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { ConfirmDialog } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { CsvExportService } from 'src/app/services/csv-export/csv-export.service';
import {
    Chart,
    ChartType,
    ChartMessage,
    FrequencyDistribution,
    PercentageFrequency,
    EchartData,
    MinMaxData,
    DEFAULT_MINUTES_VALUE,
    TimeDifferenceDetail,
} from '../chart.model';
import { canWrite, minutesToSeconds } from 'src/app/shared/common';
import { LineChartComponent } from '../line-chart/line-chart.component';
import { ExpressionNull } from 'src/app/shared/models/expression/null';
import { ChartSetupTab } from '../chart-setup/chart-setup.component';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { FrequencyDistributionDialog } from 'src/app/dialogs/frequency-distribution-dialog/frequency-distribution-dialog.component';
import { TimeDifferenceComponent } from 'src/app/dialogs/time-difference-dialog/time-difference-dialog.component';

@Component({
    selector: 'app-chart-card',
    templateUrl: './chart-card.component.html',
    styleUrls: ['./chart-card.component.scss'],
})
export class ChartCardComponent {
    @Input() set charts(value: Chart[]) {
        if (this.index >= value.length) {
            this.index = value.length - 1;
        }
        this._charts = value;
    }
    get charts(): Chart[] {
        return this._charts;
    }
    @Input() index: number;
    @Input() messages: ChartMessage[] = [];
    @Input() deviceNames: Map<number, string> = new Map();
    @Input() isEditMode = false;
    @Input() showToolbar = true;

    @Output() deleteEvent = new EventEmitter();
    @Output() indexChange = new EventEmitter<number>();
    @Output() saveChanges = new EventEmitter();
    @ViewChild('eLineChart') lineChart: LineChartComponent;

    _charts: Chart[] = [];
    lineChartImage = '';
    setupTab: ChartSetupTab;
    chartType = ChartType;
    minMaxArray: MinMaxData[] = [];
    percentageFrequency: PercentageFrequency[];
    selectedVariableName: string;
    eChartData: EchartData[];
    isEmptyRegionHidden = false;
    timeDifferenceValue: number = DEFAULT_MINUTES_VALUE;
    timeDifferenceDetails: TimeDifferenceDetail;

    get chart(): Chart {
        return this.charts[this.index];
    }

    get isLoading(): boolean {
        return this.chart == undefined || this.chart.loading;
    }

    get canEdit(): boolean {
        return canWrite();
    }

    get hasFilter(): boolean {
        return !(this.chart.filter instanceof ExpressionNull);
    }

    get paginationString(): string {
        return `(${this.index + 1}/${this.charts.length})`;
    }

    constructor(
        public csvExportService: CsvExportService,
        private dialog: DialogService,
    ) {
        this.deviceNames = new Map<number, string>();
    }

    ngOnChanges() {
        if (this.chart.chartPoints) {
            this.eChartData = this.generateChartValues(
                this.chart.chartPoints,
                this.chart.labels,
            );
        }
    }

    onWheel(event) {
        if (event.deltaY > 0) {
            this.nextChart();
        } else if (event.deltaY < 0) {
            this.previousChart();
        }
    }

    handleSave() {
        this.saveChanges.emit();
        this.isEditMode = false;
    }

    previousChart() {
        this.index = this.index == 0 ? this.charts.length - 1 : this.index - 1;
        this.indexChange.emit(this.index);
    }

    nextChart() {
        this.index = this.index == this.charts.length - 1 ? 0 : this.index + 1;
        this.indexChange.emit(this.index);
    }

    goToSignals() {
        this.setupTab = ChartSetupTab.Signals;
        this.isEditMode = true;
    }

    goToFilter() {
        this.setupTab = ChartSetupTab.Filter;
        this.isEditMode = true;
    }

    goToSetup() {
        this.setupTab = ChartSetupTab.Setup;
        this.isEditMode = true;
    }

    async deleteChart() {
        const result = await this.dialog.open(ConfirmDialog, {
            data: {
                title: 'delete.confirm',
                text: 'chart_cards.dialog.delete.text',
                confirm: 'chart_cards.dialog.delete.confirm',
                isDanger: true,
            },
        });
        if (result) {
            this.deleteEvent.emit();
        }
    }

    exportToCsv(chart) {
        // Add a "Time" column to the beginning.
        const labels = ['Time', ...chart.labels];
        this.csvExportService.exportToCsv(chart.chartPoints, 'chart', labels);
    }

    toggleScale() {
        this.lineChart.toggleScale();
    }

    /**
     * Set the aggregate value of the chart
     * @param data
     */
    setMinMax(data: MinMaxData[]): void {
        this.minMaxArray = data;
    }

    /**
     * Displays a dialog box to accept inputs for frequency distribution and returns the percentage frequency distribution.
     * @function checkPercentageDistribution
     */
    async checkPercentageDistribution() {
        const result: FrequencyDistribution = await this.dialog.open(
            FrequencyDistributionDialog,
            {
                data: {
                    minMaxArray: this.minMaxArray,
                },
            },
        );
        if (result) {
            this.selectedVariableName = result.selectedVariable;
            const frequency: number = result.frequency;
            const minValue: number = result.minValue;
            const maxValue: number = result.maxValue;
            this.percentageFrequency = this.getPercentageFrequencyDistribution(
                this.eChartData,
                frequency,
                minValue,
                maxValue,
                this.selectedVariableName,
            );
        }
    }

    /**
     * Calculates the percentage frequency distribution for a given data array, bin size, min and max values and variable.
     * @param {EchartData[]} eChartData - An array of objects with the structure of IEchartData.
     * @param {number} binSize - The bin size as a string which will be converted to a number.
     * @param {number} minValue - The min value of the distribution.
     * @param {number} maxValue - The max value of the distribution.
     * @param {string} variableName - The name of the variable used in the calculation.
     * @returns {PercentageFrequency[]} frequencies - An array of objects with range and percentage value fields
     */
    getPercentageFrequencyDistribution(
        eChartData: EchartData[],
        binSize: number,
        minValue: number,
        maxValue: number,
        variableName: string,
    ): PercentageFrequency[] {
        const frequencies: PercentageFrequency[] = [];
        const freqPercentageFractionDigits = 2;

        if (
            minValue === undefined ||
            maxValue === undefined ||
            binSize === undefined ||
            minValue > maxValue
        ) {
            return frequencies;
        }

        // Calculate the frequency for each bin
        for (let i = minValue; i < maxValue; i += binSize) {
            const binStart: number = i;
            const binEnd: number = i + binSize;
            const range = `${binStart} - ${binEnd}`;
            let frequency = 0;

            eChartData
                .filter((item) => item.name === variableName)
                .forEach((item) => {
                    item.data.forEach(([timestamp, value]) => {
                        if (value >= i && value < i + binSize) {
                            frequency++;
                        }
                    });
                });
            // Convert the frequency to percentage
            const percentageValue: string =
                frequency > 0
                    ? ((frequency / eChartData[0]?.data?.length) * 100).toFixed(
                          freqPercentageFractionDigits,
                      )
                    : '0';
            frequencies.push({ range, value: `${percentageValue}%` });
        }

        return frequencies;
    }

    /**
     * Used to create values array for the selected signals
     * @param data
     * @param labels
     * @returns
     */
    private generateChartValues(data, labels: string[]) {
        return data
            .reduce(
                (current, item) => {
                    const timestamp = item[0];
                    item.forEach((value, i) => {
                        if (i > 0 && value != null) {
                            current[i - 1].push([timestamp, value]);
                        }
                    });
                    return current;
                },
                Array.from(labels).map(() => []),
            )
            .map((points, index) => ({
                name: labels[index],
                data: points,
            }));
    }

    /**
     * Toggles the visibility of empty regions on the chart.
     * If the empty regions are currently hidden, it shows a dialog to set the time difference value.
     * If the empty regions are currently shown, it updates the time difference value and emits the changes to the chart sync service.
     */
    async hideEmptyRegions() {
        if (this.isEmptyRegionHidden) {
            this.isEmptyRegionHidden = false;
            this.timeDifferenceDetails.isEmptyRegionHidden = false;
            return;
        }
        const result: TimeDifferenceComponent = await this.dialog.open(
            TimeDifferenceComponent,
            {
                data: {
                    timeDifferenceValue: this.timeDifferenceValue,
                },
            },
        );
        if (result) {
            this.isEmptyRegionHidden = true;
            this.timeDifferenceValue = result['time'];
            this.timeDifferenceDetails = {
                isEmptyRegionHidden: this.isEmptyRegionHidden,
                timeDifference: minutesToSeconds(this.timeDifferenceValue),
            };
        }
    }
}
