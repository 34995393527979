<ng-container *ngIf="isNull">
    <expr-null
        [expression]="expression"
        [editContext]="editContext"
    ></expr-null>
</ng-container>
<ng-container *ngIf="isPrimitive">
    <expr-primitive [expression]="expression" [editContext]="editContext">
    </expr-primitive>
</ng-container>
<ng-container *ngIf="isVariable">
    <expr-variable [expression]="expression" [editContext]="editContext">
    </expr-variable>
</ng-container>
<ng-container *ngIf="isList">
    <expr-list
        [expression]="expression"
        [editContext]="editContext"
    ></expr-list>
</ng-container>
<ng-container *ngIf="isOperation">
    <expr-operation [expression]="expression" [editContext]="editContext">
    </expr-operation>
</ng-container>
