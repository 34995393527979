import { NgModule } from '@angular/core';
import { AppMaterialModule } from 'src/app/services/app-material/app-material.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { ChartGeneralSetupComponent } from './chart-general-setup.component';

@NgModule({
  imports: [ AppMaterialModule, ScrollingModule, TableVirtualScrollModule ],
  declarations: [ ChartGeneralSetupComponent ],
  exports: [ ChartGeneralSetupComponent ]
})
export class ChartGeneralSetupModule { }
