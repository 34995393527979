import { NgModule } from '@angular/core';
import { AppMaterialModule } from 'src/app/services/app-material/app-material.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { ChartFilterComponent } from './chart-filter.component';
import { ExpressionModule } from 'src/app/expression/expression.module';

@NgModule({
    imports: [
        AppMaterialModule,
        ScrollingModule, 
        TableVirtualScrollModule,
        ExpressionModule
    ],
    declarations: [ChartFilterComponent],
    exports: [ChartFilterComponent]
})
export class ChartFilterModule { }
