<h1 mat-dialog-title>{{ 'time_difference.heading' | translate }}</h1>
<div mat-dialog-content class="mat-typography">
    <form [formGroup]="form" class="mt-2 overflow-auto">
        <mat-form-field class="full-width">
            <mat-label
                >{{ 'time_difference.heading' | translate }} (m)</mat-label
            >
            <input
                matInput
                formControlName="time"
                class="form-control"
                type="number"
            />
        </mat-form-field>
    </form>
    <sz-dialog-button-bar
        [primaryText]="'btn.confirm' | translate"
        [primaryColor]="'primary'"
        (primaryClick)="setTimeDifferenceValue()"
        (secondaryClick)="dialogRef.close()"
        (primaryDisabled)="(!form.valid ? true : false)"
    />
</div>
