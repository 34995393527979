import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IExpressionError } from '../shared/models/expression/expression';

@Pipe({
    name: 'expressionError',
    pure: false,
})
export class ExpressionErrorPipe implements PipeTransform {
    private translatedError = '';

    constructor(private translate: TranslateService) {}

    transform(error: IExpressionError): string {
        if (!error || !error.term) {
            return '';
        }

        const params = error.params ?? {};
        // The array can't be empty when passed to translate.get, so add an
        // empty key.
        const translateKeys = [''];

        // Find the values that need translated.
        for (const param of Object.keys(params)) {
            const value = params[param];
            // Translate all string values.
            if (typeof value === typeof '') {
                translateKeys.push(value.toString());
            }
        }

        // Translate the parameter values.
        this.translate.get(translateKeys).subscribe((subResults) => {
            // Go through all parameters and insert the translated values.
            for (const param of Object.keys(params)) {
                const value = params[param];
                // Insert translated value.
                if (subResults[value]) {
                    params[param] = subResults[value];
                }
            }
            this.translate.get(error.term, params).subscribe((result) => {
                this.translatedError = result;
            });
        });
        return this.translatedError;
    }
}
