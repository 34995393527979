import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import { ExpressionEditContext } from 'src/app/shared/models/expression/edit-context';
import { ExpressionList } from 'src/app/shared/models/expression/list';
import { ExpressionNull } from 'src/app/shared/models/expression/null';
import { ExpressionOperation } from 'src/app/shared/models/expression/operation';
import { Operators } from 'src/app/shared/models/expression/operator';
import { ExpressionPrimitive } from 'src/app/shared/models/expression/primitive';
import { ExpressionType } from 'src/app/shared/models/expression/type';
import { ExpressionVariable } from 'src/app/shared/models/expression/variable';
import { Chart, ChartMessage } from '../chart.model';

enum FilterStatus {
    NoFilter,
    InvalidFilter,
    ValidFilter,
}

@Component({
    selector: 'app-chart-filter',
    templateUrl: './chart-filter.component.html',
})
export class ChartFilterComponent implements OnChanges, OnDestroy {
    @Input() messages: ChartMessage[];
    @Input() isShown: boolean;

    _chart: Chart;
    editContext: ExpressionEditContext;
    filterStatusEnum = FilterStatus;

    @Input() set chart(value: Chart) {
        this._chart = value;
        this.editContext = new ExpressionEditContext(
            value.filter,
            ExpressionType.Boolean,
            (expression) => (value.filter = expression),
        );
    }
    get chart(): Chart {
        return this._chart;
    }

    get filterStatus(): FilterStatus {
        if (!this.editContext || this.editContext.rootIsNull) {
            return FilterStatus.NoFilter;
        } else if (this.editContext.rootIsValid) {
            return FilterStatus.ValidFilter;
        } else {
            return FilterStatus.InvalidFilter;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isShown && changes.isShown.currentValue) {
            this.updateOptions();
        }
    }

    ngOnDestroy(): void {
        this.chart.filter = this.editContext.root;
    }

    updateOptions() {
        if (!this.chart || !this.messages) {
            return;
        }
        const numberMap = new Map<number, number>();
        // Add a default number.
        numberMap.set(0, 0);
        const stringMap = new Map<string, number>();
        // Add a default string.
        stringMap.set('String', 0);
        // TODO: in the future, add suggestions based on the chart data.
        // for (let row of this.chart.chartPoints) {
        //     for (let value of row.slice(1)) {
        //         let map: Map<any, number>;
        //         if (typeof value == 'number') {
        //             map = numberMap;
        //         } else if (typeof value == 'string') {
        //             map = stringMap;
        //         }
        //         let oldCount = map.get(value) ?? 0;
        //         map.set(value, oldCount + 1);
        //     }
        // }
        const numberList = [...numberMap.keys()];
        numberList.sort((a, b) => numberMap.get(b) - numberMap.get(a));
        numberList.splice(5); // Only keep the most common numbers.
        // numberList.sort((a, b) => a - b);
        const stringList = [...stringMap.keys()];
        stringList.sort((a, b) => stringMap.get(b) - stringMap.get(a));
        stringList.splice(5); // Only keep the most common strings.

        this.editContext.options = [
            ...Object.values(Operators).map(
                (op) =>
                    new ExpressionOperation(op, [
                        new ExpressionNull(),
                        new ExpressionNull(),
                    ]),
            ),
            new ExpressionList([new ExpressionNull(), new ExpressionNull()]),
            ...this.chart
                .allSignals(this.messages)
                .map((v) => new ExpressionVariable(v)),
            ...numberList.map(
                (number) =>
                    new ExpressionPrimitive(
                        ExpressionType.Number,
                        number.toString(),
                    ),
            ),
            ...stringList.map(
                (string) =>
                    new ExpressionPrimitive(ExpressionType.String, string),
            ),
        ];
    }
}
