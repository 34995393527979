<div
    class="!bg-gray-500 expr-segment"
    [ngClass]="isSelected ? 'selected' : ''"
    (click)="onClick($event)"
>
    <expr-child
        *ngFor="let child of expression.children"
        class="!mr-3 !last:mr-0"
        [expression]="child"
        [editContext]="editContext"
    >
    </expr-child>
</div>
