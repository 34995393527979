<div class="setup-container h-full">
    <mat-tab-group
        mat-align-tabs="center"
        [(selectedIndex)]="selectedTabIndex"
        class="h-full"
    >
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="mr-2" svgIcon="chart-timeline-variant" />
                {{ 'can_database.signals' | translate }}
            </ng-template>
            <div class="h-full">
                <app-chart-signals
                    [chart]="chart"
                    [messages]="messages"
                    [deviceNames]="deviceNames"
                />
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="mr-2" svgIcon="filter-cog" />
                {{ 'common.filter' | translate }}
            </ng-template>
            <div class="h-full">
                <app-chart-filter
                    [chart]="chart"
                    [messages]="messages"
                    [isShown]="selectedTabIndex === 1"
                />
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="mr-2" svgIcon="tune-vertical" />
                {{ 'btn.setup' | translate }}
            </ng-template>
            <div class="h-full">
                <app-chart-general-setup
                    [chart]="chart"
                    (deleteChart)="deleteChart.emit()"
                />
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
