import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectOption } from 'src/app/settings/settings.model';
import { Message, Signal } from 'src/app/shared/models/can-database';

@Component({
    templateUrl: './signal-selection-dialog.component.html',
})
export class SignalSelectionDialog implements OnInit {
    public selectedMessage?: Message;
    public messages: Message[];
    public selectedSignals: Signal[];
    public messageOptions: SelectOption[] = [];

    @Input()
    set selectedMessageId(_sel: any) {
        this.messages.forEach((msg: any) => {
            if (msg.id === _sel) {
                this.selectedMessage = msg;
            }
        });
    }

    get selectedMessageId() {
        return this.selectedMessage?.id;
    }

    /**
     * Get the list of signals to display.
     */
    get signalOptions(): Signal[] {
        return this.selectedMessage?.signals ?? [];
    }

    constructor(
        public dialogRef: MatDialogRef<SignalSelectionDialog>,
        @Inject(MAT_DIALOG_DATA) public data: { messages: Message[] },
    ) {
        this.messages = data.messages ?? [];
        this.messageOptions = (data.messages ?? []).map((m) => ({
            value: m.id,
            name: m.descriptor,
        }));
    }

    ngOnInit() {
        if (this.messages.length) {
            this.selectedMessageId = this.messages[0].id;
        }
        this.selectedSignals = [];
    }

    /**
     * Select all the signals for the currently selected message, and close
     * the dialog with the result.
     */
    selectAllSignals() {
        for (const signal of this.signalOptions) {
            const foundIndex = this.findSelectedSignalIndex(signal);
            if (foundIndex < 0) {
                // Add the signal
                this.selectedSignals.push(signal);
            }
        }
        this.handleSave();
    }

    /**
     * Find the index of the signal in the selectedSignals array.
     * Return -1 if the signal is not selected.
     */
    findSelectedSignalIndex(signal: Signal): number {
        for (let i = 0; i < this.selectedSignals.length; i++) {
            const selectedSignal = this.selectedSignals[i];
            if (selectedSignal.id == signal.id) {
                return i;
            }
        }
        return -1;
    }

    /**
     * If the signal is not selected, select it.
     * If the signal is selected, deselect it.
     */
    toggleSignal(signal: Signal) {
        const foundIndex = this.findSelectedSignalIndex(signal);
        if (foundIndex >= 0) {
            // Remove the signal
            this.selectedSignals.splice(foundIndex, 1);
        } else {
            // Add the signal
            this.selectedSignals.push(signal);
        }
    }

    /**
     * Check if the signal is selected.
     */
    isSignalSelected(v: Signal): boolean {
        return this.findSelectedSignalIndex(v) >= 0;
    }

    handleSave() {
        this.dialogRef.close(this.selectedSignals);
    }
}
