import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { SelectDevicesDialog } from 'src/app/dialogs/select-devices-dialog/select-devices-dialog.component';
import { SignalSelectionDialog } from 'src/app/dialogs/signal-selection-dialog/signal-selection-dialog.component';
import { SimpleInputDialog } from 'src/app/dialogs/simple-input-dialog/simple-input-dialog.component';
import { ExpressionEditContext } from 'src/app/shared/models/expression/edit-context';
import { Expression } from 'src/app/shared/models/expression/expression';
import { ExpressionList } from 'src/app/shared/models/expression/list';
import { ExpressionNull } from 'src/app/shared/models/expression/null';
import { ExpressionOperation } from 'src/app/shared/models/expression/operation';
import { Operators } from 'src/app/shared/models/expression/operator';
import { ExpressionPrimitive } from 'src/app/shared/models/expression/primitive';
import { ExpressionType } from 'src/app/shared/models/expression/type';
import { ExpressionVariable } from 'src/app/shared/models/expression/variable';
import { CalculatedSignal, Chart, ChartMessage } from '../chart.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';

@Component({
    selector: 'app-chart-signals',
    templateUrl: './chart-signals.component.html',
    styleUrls: ['./chart-signals.component.scss'],
})
export class ChartSignalsComponent implements OnInit {
    @Input() chart: Chart;
    @Input() messages: ChartMessage[];
    @Input() deviceNames: Map<number, string>;

    selectedSignalUuid: string;
    expressionOptions: Expression[];
    editContext: ExpressionEditContext;
    s;
    signalName = new FormControl('', Validators.required);

    get hasSelectedSignal(): boolean {
        return !!this.selectedSignalUuid;
    }

    constructor(
        private dialog: DialogService,
        public appService: AppService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.updateOptions();
        // Update the signal name when the input changes.
        this.signalName.valueChanges.subscribe((name) => {
            const signal = this.chart.calculatedSignals.find(
                (signal) => signal.uuid == this.selectedSignalUuid
            );
            if (signal && name.trim().length > 0) {
                signal.name = name;
            }
        });
    }

    /**
     * Open the SignalSelectionDialog to select signals to add.
     */
    async addSignals() {
        const dialogData = { data: { messages: this.messages } };
        const result = await this.dialog.open(
            SignalSelectionDialog,
            dialogData
        );

        if (!result) {
            return;
        }
        this.chart.calculatedSignals.push(
            ...result.map((signal) => {
                const message = this.messages.find((m) =>
                    m.signals.find((v) => v.id == signal.id)
                );
                let signalName = signal.name;
                if (signal.unit) {
                    signalName += ' [' + signal.unit + ']';
                }
                return new CalculatedSignal(
                    signalName,
                    message.deviceIds,
                    new ExpressionVariable(signal)
                );
            })
        );
        // Update the options to include the new signal.
        this.updateOptions();
    }

    /**
     * Add an empty calculated signal.
     */
    addCalculatedSignal() {
        const deviceIds =
            this.messages.length > 0 ? this.messages[0].deviceIds : [];
        this.translate
            .get('can_database.signal.name')
            .subscribe((translated) => {
                this.chart.calculatedSignals = [
                    ...this.chart.calculatedSignals,
                    new CalculatedSignal(
                        translated,
                        deviceIds,
                        new ExpressionPrimitive(ExpressionType.Number, '0')
                    ),
                ];
            });
    }

    /**
     * Handle a reordering event in the selected signals list.
     */
    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(
            this.chart.calculatedSignals,
            event.previousIndex,
            event.currentIndex
        );
    }

    /**
     * Get all the device names for a signal.
     */
    getDeviceNamesString(signal: CalculatedSignal): string {
        const names: string[] = [];
        for (const deviceId of signal.deviceIds) {
            let deviceName = deviceId.toString();
            if (this.deviceNames.has(deviceId)) {
                deviceName = this.deviceNames.get(deviceId);
            }
            names.push(deviceName);
        }
        return names.join(' • ');
    }

    /**
     * Edit the given signal's name.
     */
    async editName(signal: CalculatedSignal) {
        const result = await this.dialog.open(SimpleInputDialog, {
            data: {
                title: 'btn.edit_name',
                text: 'chart_setup.edit_signal_hint',
                label: 'can_database.signal.name',
                input: signal.name,
            },
        });
        if (result) {
            signal.name = result;
        }
    }

    /**
     * Edit the given signal.
     */
    editSignal(signal: CalculatedSignal) {
        if (this.selectedSignalUuid == signal.uuid) {
            this.selectedSignalUuid = null;
            this.signalName.setValue('');
        } else {
            this.editContext = new ExpressionEditContext(
                signal.expression,
                ExpressionType.Scalar,
                (expression) => (signal.expression = expression)
            );
            this.editContext.options = this.expressionOptions;
            this.selectedSignalUuid = signal.uuid;
            this.signalName.setValue(signal.name);
        }
    }

    /**
     * Copy the given signal.
     */
    copySignal(signal: CalculatedSignal) {
        this.chart.calculatedSignals = [
            ...this.chart.calculatedSignals,
            new CalculatedSignal(
                signal.name,
                signal.deviceIds,
                signal.expression.clone()
            ),
        ];
    }

    /**
     * Select devices for the given signal.
     */
    async selectDevices(signal: CalculatedSignal) {
        const devices = [];
        for (const id of this.deviceNames.keys()) {
            devices.push({
                id,
                selected: signal.deviceIds.includes(id),
                identifier: this.deviceNames.get(id),
            });
        }
        const result = await this.dialog.open(SelectDevicesDialog, {
            width: '450px',
            data: {
                title: 'btn.select_devices',
                devices,
                selectedDeviceIds: signal.deviceIds,
            },
        });
        if (result) {
            signal.deviceIds = result;
        }
    }

    /**
     * Remove the given selected signal.
     */
    removeSignal(signal: CalculatedSignal) {
        const index = this.chart.calculatedSignals.indexOf(signal);
        this.chart.calculatedSignals.splice(index, 1);
    }

    updateOptions() {
        if (!this.chart || !this.messages) {
            return;
        }
        const numberMap = new Map<number, number>();
        // Add a default number.
        numberMap.set(0, 0);
        const stringMap = new Map<string, number>();
        // Add a default string.
        stringMap.set('String', 0);
        const numberList = [...numberMap.keys()];
        numberList.sort((a, b) => numberMap.get(b) - numberMap.get(a));
        numberList.splice(5); // Only keep the most common numbers.
        // numberList.sort((a, b) => a - b);
        const stringList = [...stringMap.keys()];
        stringList.sort((a, b) => stringMap.get(b) - stringMap.get(a));
        stringList.splice(5); // Only keep the most common strings.

        this.expressionOptions = [
            ...Object.values(Operators).map(
                (op) =>
                    new ExpressionOperation(
                        op,
                        Array(op.inputTypes.length)
                            .fill(null)
                            .map((_) => new ExpressionNull())
                    )
            ),
            new ExpressionList([new ExpressionNull(), new ExpressionNull()]),
            ...this.chart
                .allSignals(this.messages)
                .map((v) => new ExpressionVariable(v)),
            ...numberList.map(
                (number) =>
                    new ExpressionPrimitive(
                        ExpressionType.Number,
                        number.toString()
                    )
            ),
            ...stringList.map(
                (string) =>
                    new ExpressionPrimitive(ExpressionType.String, string)
            ),
        ];
    }
}
