<div class="flex flex-col items-center justify-start gap-1 self-stretch">
    <div class="flex w-full items-center justify-center" (click)="deselect()">
        <div
            class="mt-8 flex min-h-[80px] items-center justify-center rounded-xl bg-gray-300 px-6 py-2 dark:bg-gray-600"
        >
            <expr-child
                [expression]="editContext.root"
                [editContext]="editContext"
            >
            </expr-child>
        </div>
    </div>
    <div
        class="selected-container flex flex-col items-center justify-start gap-2"
    >
        <div class="flex flex-row gap-4">
            <button
                mat-icon-button
                matTooltip="{{
                    'expressions.toggle_syntax_errors' | translate
                }}"
                (click)="editContext.toggleSyntaxCheck()"
            >
                <mat-icon
                    [class.text-red-500]="
                        !editContext.root.isSyntaxValid ||
                        !editContext.rootIsCorrectType
                    "
                    [svgIcon]="
                        editContext.showSyntaxErrors ? 'bug' : 'bug-outline'
                    "
                >
                </mat-icon>
            </button>
            <ng-container *ngIf="!editContext.showSyntaxErrors">
                <button
                    mat-icon-button
                    matTooltip="{{ 'btn.undo' | translate }}"
                    [disabled]="!editContext.canUndo"
                    (click)="editContext.undo()"
                >
                    <mat-icon svgIcon="undo"></mat-icon>
                </button>
                <button
                    mat-icon-button
                    matTooltip="{{ 'delete' | translate }}"
                    [disabled]="!editContext.canDeleteSelected"
                    (click)="editContext.deleteSelected()"
                >
                    <mat-icon svgIcon="delete"></mat-icon>
                </button>
                <button
                    mat-icon-button
                    matTooltip="{{ 'expressions.edit_value' | translate }}"
                    [disabled]="!editContext.selectedIsPrimitive"
                    (click)="editPrimitive()"
                >
                    <mat-icon svgIcon="pencil"></mat-icon>
                </button>
                <button
                    mat-icon-button
                    matTooltip="{{ 'expressions.shrink_list' | translate }}"
                    [disabled]="
                        !editContext.selectedIsList ||
                        editContext.selected.children.length <= 2
                    "
                    (click)="shrinkList()"
                >
                    <mat-icon svgIcon="playlist-minus"></mat-icon>
                </button>
                <button
                    mat-icon-button
                    matTooltip="{{ 'expressions.grow_list' | translate }}"
                    [disabled]="!editContext.selectedIsList"
                    (click)="growList()"
                >
                    <mat-icon svgIcon="playlist-plus"></mat-icon>
                </button>
            </ng-container>
        </div>
        <ng-container [ngSwitch]="content">
            <div
                *ngSwitchCase="DisplayContent.Options"
                class="m-2 flex flex-row flex-wrap items-start justify-center gap-1"
            >
                <div
                    *ngFor="let child of editContext.filteredOptions"
                    class="flex justify-center"
                >
                    <div class="relative float-left">
                        <expr-child [expression]="child"> </expr-child>
                        <div
                            (click)="selectNewItem(child)"
                            class="absolute inset-0"
                        ></div>
                    </div>
                </div>
            </div>
            <h2 *ngSwitchCase="DisplayContent.HelpMessage">
                {{ 'expressions.help_message' | translate }}
            </h2>
            <h2 *ngSwitchCase="DisplayContent.SyntaxResults">
                {{
                    (editContext.root.isSyntaxValid
                        ? editContext.rootIsCorrectType
                            ? 'expressions.valid_syntax'
                            : 'expressions.invalid_expression_type'
                        : 'expressions.invalid_syntax'
                    ) | translate : { correctType: rootTypeName }
                }}
            </h2>
        </ng-container>
    </div>
</div>
