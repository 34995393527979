import { Injectable, QueryList } from '@angular/core';
import * as echarts from 'echarts';
import { ChartCardComponent } from 'src/app/chart/chart-card/chart-card.component';
import { ChartType, MapPoint, TimeParams } from 'src/app/chart/chart.model';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { SNACKBAR_SHORT_DURATION } from '../constants';

@Injectable({
    providedIn: 'root',
})
export class ChartSyncService {
    marker: any = {};
    markers: any[] = [];
    syncedMarkers: any[] = [];
    isChartsSync = false;
    chartsArray: QueryList<ChartCardComponent>;
    echartPoints: [Date, number][] = [];

    constructor(private snackBar: SnackBarService) {}

    syncCharts(charts) {
        this.isChartsSync = !this.isChartsSync;
        this.chartsArray = charts;
        charts.forEach((c) =>
            c.lineChart ? c.lineChart.onHighlight(this.isChartsSync) : '',
        );
        if (this.isChartsSync) {
            echarts.connect('echart');
        } else {
            echarts.disconnect('echart');
        }
    }

    /**
     * Highlights a specific point on the chart.
     * @param {Date} time - The time of the point to highlight
     * @param {ChartType} chartCard - The type of chart to highlight (either 'map' or 'line')
     */
    highlightChartPoint(time: Date, chartCard: ChartType): void {
        let chartPoints: MapPoint[] | [Date, number][];
        let errorMessage: string;
        let timeParam: string | number;
        const combinedFrequency: number = this.getEchartAndMapFrequency();

        if (
            chartCard === ChartType.MAP &&
            this.markers &&
            this.markers.length > 0
        ) {
            chartPoints = this.markers[0];
            timeParam = TimeParams.Time;
            errorMessage = 'reports.no_gps_data_found';
        } else if (
            chartCard === ChartType.LINE &&
            this.echartPoints &&
            this.echartPoints.length > 0
        ) {
            chartPoints = this.echartPoints;
            timeParam = TimeParams.Index;
            errorMessage = 'reports.no_logged_data_found';
        }
        if (!this.isChartsSync || !chartPoints || !chartPoints.length) {
            return;
        }

        const tmOffset: number = Math.min(
            ...chartPoints
                .map((t) => t[timeParam]?.getTime() - time.getTime())
                .filter((f) => f >= 0),
        );
        const minValueIndex: number = chartPoints.findIndex(
            (t) => t[timeParam]?.getTime() === time.getTime() + tmOffset,
        );

        if (minValueIndex >= 0 && tmOffset <= combinedFrequency) {
            if (chartCard === ChartType.MAP) {
                Object.assign(this.marker, chartPoints[minValueIndex]);
            } else {
                this.chartsArray.forEach((c) =>
                    c.lineChart
                        ? c.lineChart.highlightPoint(minValueIndex)
                        : '',
                );
            }
        } else {
            this.snackBar.open(errorMessage, {
                duration: SNACKBAR_SHORT_DURATION,
            });
        }
    }

    /**
     * Calculates the combined frequency of the Echart and Map points.
     * @returns {number} The combined frequency of map and Echart logs.
     */
    private getEchartAndMapFrequency(): number {
        let mapLogFrequency = 0,
            eChartLogFrequency = 0;
        if (this.markers && this.markers.length > 0) {
            mapLogFrequency = this.calculateFrequency(
                this.markers,
                ChartType.MAP,
            );
        }
        if (this.echartPoints && this.echartPoints.length > 0) {
            eChartLogFrequency = this.calculateFrequency(
                this.echartPoints,
                ChartType.LINE,
            );
        }
        return mapLogFrequency + eChartLogFrequency;
    }

    /**
     * Calculates the frequency between timestamps in data based on the specified chart type.
     * @param {MapPoint[][] | [Date, number][]} data - The data containing timestamps.
     * @param {ChartType} type - The type of chart (e.g., MAP) to determine the data structure.
     * @returns {number} The frequency between timestamps in milliseconds.
     */
    private calculateFrequency(
        data: MapPoint[][] | [Date, number][],
        type: ChartType,
    ): number {
        const timeStamps: Date[] =
            type === ChartType.MAP
                ? data[0].map((item) => item.time)
                : data.map((item) => item[0]);
        const logFrequency: number = timeStamps.reduce(
            (minDiff, current, index, array) => {
                if (index > 0) {
                    const prevTimestamp: Date = array[index - 1];
                    const timeDifference: number = this.getTimeDifference(
                        prevTimestamp,
                        current,
                    );
                    return Math.min(minDiff, timeDifference);
                } else {
                    return minDiff;
                }
            },
            Infinity,
        );
        return logFrequency;
    }

    /**
     * Calculates the difference in milliseconds between two timestamps.
     * @param {Date} sourceTimeStamp - The source timestamp in Date format.
     * @param {Date} targetTimeStamp - The target timestamp in Date format.
     * @returns {number} The absolute difference in milliseconds between the two timestamps.
     */
    private getTimeDifference = (
        sourceTimeStamp: Date,
        targetTimeStamp: Date,
    ): number => {
        const sourceTime: number = new Date(sourceTimeStamp).getTime();
        const targetTime: number = new Date(targetTimeStamp).getTime();
        return Math.abs(sourceTime - targetTime);
    };
}
